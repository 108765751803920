function fallbackCopyTextToClipboard(text: string, input: HTMLInputElement) {
  try {
    input.focus();
    input.select();
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }
}

//https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
export function copyTextToClipboard(text: string, input?: HTMLInputElement) {
  if (!navigator.clipboard && input) {
    fallbackCopyTextToClipboard(text, input); //in case clipboard api is not avilable, we can copy directly from an input element
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {},
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}
