import { Action, DispatchFunc } from "frontend/state/actions";
import {
  createProject as internalCreateProject,
  deleteProject as internalDeleteProject,
  editProject as internalEditProject,
} from "../api";

import { Project, UserProjectPermission } from "shared/datamodel/schemas/project";

export async function createProject(
  name: string,
  usersProjectPermission?: UserProjectPermission[] | [],
  teamId?: number,
  dispatch?: DispatchFunc
): Promise<Project> {
  const project = await internalCreateProject(name, teamId, usersProjectPermission);
  dispatch && dispatch({ type: Action.CreatedProject, payload: project });
  return { ...project };
}

export async function editProject(
  project: Project,
  requestPayload: { name?: string; users?: UserProjectPermission[] | null; newOwner?: number },
  dispatch: any
): Promise<Project | undefined> {
  dispatch({ type: Action.EditedProject, payload: project });
  try {
    return await internalEditProject(project, requestPayload);
  } catch (e: any) {
    dispatch({ type: Action.FailedEditProject, payload: project });
  }
}

export async function deleteProject(project: Project, dispatch: any) {
  dispatch({ type: Action.DeleteProject, payload: project });
  try {
    await internalDeleteProject(project.documentId);
  } catch {
    dispatch({ type: Action.FailedDeleteProject, payload: project });
  }
}
