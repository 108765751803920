import { askToEdit } from "frontend/api";
import useStateValue from "frontend/state/value";
import tracking from "frontend/tracking";
import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import consts from "shared/consts";

export const isAskToEditEnableAtom = atom<boolean>(true);
export const initButtonText = "Viewer Restricted (Free)";

export function useRestricted() {
  const [isAskToEditEnable, setIsAskToEditEnable] = useAtom(isAskToEditEnableAtom);
  const [{ user }] = useStateValue();

  const [showAskToEditToast, setShowAskToEditToast] = useState<boolean>(false);
  const [viewerButtonText, setViewerButtonText] = useState(initButtonText);

  //Hide the ask to edit message after some interval
  useEffect(() => {
    let timeOut: any;
    if (showAskToEditToast) {
      timeOut = setTimeout(() => {
        setShowAskToEditToast(false);
      }, 6000);
    }
    return () => timeOut && clearTimeout(timeOut);
  }, [showAskToEditToast]);


  //Hide the request sent message after some interval
  useEffect(() => {
    let timeOut: any;
    if (viewerButtonText !== initButtonText) {
      timeOut = setTimeout(() => {
        setViewerButtonText(initButtonText);
      }, 6000);
    }
    return () => timeOut && clearTimeout(timeOut);
  }, [viewerButtonText]);

  function handleAskToEditClick(source: string) {
    setShowAskToEditToast(true);
    setIsAskToEditEnable && setIsAskToEditEnable(false);
    askToEdit();
    setViewerButtonText("Request Sent");
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.RESTRICTED_ACTION,
      "ask_to_edit",
      source,
      user?.account?.id,
      user?.id
    );
  }

  return {
    isAskToEditEnable,
    handleAskToEditClick,
    showAskToEditToast,
    viewerButtonText,
  };
}
