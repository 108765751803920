import style from "./toast.module.css";

export default function SendInviteToast({ inviteeName }: { inviteeName: string }) {

  return (
    <div className={style.container}>
      <div className={style.content}>
        {"An invite has been sent to " + inviteeName + " to join your WorkCanvas account."}
      </div>
    </div>
  );
}