import { ButtonStyle } from "frontend/ui-components/styled-button";
import UpgradeModalGeneric, { UpgradeModalNeededProps } from "./upgrade-modal";
import { getPathPrefix } from '../../utils/getPathPrefix';

export default function UpgradeModalForUnlimitedElements(props: UpgradeModalNeededProps) {
  return (
    <UpgradeModalGeneric
      trackingEvent="elements-limit"
      title="Upgrade for unlimited shapes"
      content={"Effortlessly visualize workflows and processes with unlimited shapes and connectors."}
      bulletpoints={[
        "Unlimited Infinite canvases",
        "Blazing Fast Multiplayer",
        "Effortless Team Collaboration",
        "A world of templates",
        "Quality file export",
      ]}
      image={getPathPrefix("/images/upgrade-modal-images/upgrade-for-unlimited-elements.png")}
      upgradeButtonStyle={ButtonStyle.filled}
      showCancelLine={true}
      {...props}
    />
  );
}
