import Modal from "frontend/modal/modal";
import TemplatePreviewModal from "frontend/modals/template-preview-modal";
import { useEffect, useState } from "react";
import { CanvasTemplateMinimal } from "shared/datamodel/schemas";
import LoadedState from "frontend/state/loadedState";
import useModalDeepLink from "frontend/hooks/use-modal-deep-link";
import useCanvasTemplates from "frontend/hooks/use-canvas-templates";
import { RESET } from "jotai/utils";
import TemplatesModal from "./templates-modal";
import useFeatureValue from "frontend/hooks/use-features";
import consts from "shared/consts";
import tracking from "frontend/tracking";
import { ModalAction, ModalType } from "frontend/modals/modal-manager";

export const SELECTED_CATEGORY = "category";

export default function TemplatesWrapper({
  setIsLoading,
  handleModalAction,
  onShowUpgradeModal,
  currentCanvasDocumentId,
  onCreateTemplate,
}: {
  setIsLoading: (isLoading: boolean) => void;
  handleModalAction: (action: ModalAction) => void;
  onShowUpgradeModal?: (value: string | null) => void;
  currentCanvasDocumentId?: string;
  onCreateTemplate?: () => void;
}) {
  const [currentModalType, setCurrentModalType] = useState<ModalType>(ModalType.None);

  const { modalTypeFromUrl, nameFromUrl, setModalDeepLink, getId } = useModalDeepLink();
  const { loadingState, templates } = useCanvasTemplates();
  const [previewTemplate, setPreviewTemplate] = useState<CanvasTemplateMinimal | null>(null);
  const [disableOutsideDetection, setDisableOutsideDetection] = useState(false);
  const availableTemplates = useFeatureValue(consts.FEATURE_NAMES.TEMPLATES);

  const onClickBackground = () => {
    if (!disableOutsideDetection) {
      setCurrentModalType(ModalType.None);
      setModalDeepLink({ modalType: RESET });
    }
  };

  const handlePreviewTemplate = () => {
    const shouldRenderPreviewTemplate =
      modalTypeFromUrl === ModalType.PreviewTemplate &&
      nameFromUrl &&
      currentModalType !== ModalType.TemplateLibrary &&
      loadingState === LoadedState.finished;
    if (shouldRenderPreviewTemplate) {
      const id = getId();
      const foundId = templates?.find((t) => t.id == id);
      if (id && foundId) {
        setPreviewTemplate(foundId);
        setCurrentModalType(ModalType.PreviewTemplate);
      } else {
        setPreviewTemplate(null);
      }
    } else {
      setPreviewTemplate(null);
    }
  };

  useEffect(() => {
    if (loadingState === LoadedState.finished) {
      setIsLoading(false);
      handlePreviewTemplate();
    } else {
      setIsLoading(true);
    }
  }, [modalTypeFromUrl, nameFromUrl, loadingState]);

  useEffect(() => {
    //TODO: this is a mess, refactor
    if (modalTypeFromUrl === ModalType.TemplateLibrary) {
      if (nameFromUrl) {
        const isTemplateLibraryCategorySelected = nameFromUrl.startsWith(SELECTED_CATEGORY);
        if (isTemplateLibraryCategorySelected) {
          setCurrentModalType(ModalType.TemplateLibrary);
        } else {
          setModalDeepLink({ modalType: ModalType.PreviewTemplate, name: nameFromUrl });
        }
      } else {
        setCurrentModalType(ModalType.TemplateLibrary);
      }
    }
  }, [modalTypeFromUrl, nameFromUrl]);

  function templateLibraryDismiss() {
    setModalDeepLink({ modalType: RESET });
    setCurrentModalType(ModalType.None);
    handleModalAction({ type: "dismissTemplate", payload: {} });
  }

  const renderModal = () => {
    switch (currentModalType) {
      case ModalType.PreviewTemplate:
        return previewTemplate ? (
          <Modal dimBackground={true} onClickBackground={onClickBackground}>
            <TemplatePreviewModal
              template={previewTemplate}
              chooseTemplate={() => {
                handleModalAction({ type: "chooseTemplate", payload: { template: previewTemplate } });
                setCurrentModalType(ModalType.None);
              }}
              setDisableOutsideDetection={setDisableOutsideDetection}
              onDismiss={() => {
                setPreviewTemplate(null);
                setCurrentModalType(ModalType.None);
                setModalDeepLink({ modalType: RESET });
              }}
            />
          </Modal>
        ) : null;
      case ModalType.TemplateLibrary:
        return (
          <Modal dimBackground={true} onClickBackground={templateLibraryDismiss}>
            <TemplatesModal
              onDismiss={templateLibraryDismiss}
              onChooseTemplate={(template) => {
                setCurrentModalType(ModalType.None);
                handleModalAction({ type: "chooseTemplate", payload: { template } });
              }}
              showUpgradeCTA={availableTemplates != "all"}
              onUpgradeCTAClick={(source: string) => {
                tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.UPGRADE_CTA);
                tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "upgrade-cta-click", "templates-modal", source);
                onShowUpgradeModal && onShowUpgradeModal("templates-modal");
                templateLibraryDismiss();
              }}
              currentCanvasDocumentId={currentCanvasDocumentId}
              onCreateTemplate={onCreateTemplate}
            />
          </Modal>
        );
      default:
        return null;
    }
  };

  return renderModal();
}
