import { useEffect, useState } from "react";
import useModalDeepLink from "frontend/hooks/use-modal-deep-link";
import TemplatesWrapper from "./templates-wrapper";

export type ModalAction = {
  type: string;
  payload: any;
} | null;

export const enum ModalType {
  None = "none",
  Templates = "template",
  PreviewTemplate = "template",
  TemplateLibrary = "templateLibrary",
}

export default function ModalManager({
  setIsLoading,
  handleModalAction,
  onShowUpgradeModal,
  currentCanvasDocumentId,
  onCreateTemplate,
}: {
  setIsLoading: (isLoading: boolean) => void;
  handleModalAction: (action: ModalAction) => void;
  onShowUpgradeModal?: (value: string | null) => void;
  currentCanvasDocumentId?: string;
  onCreateTemplate?: () => void;
}) {
  const { modalTypeFromUrl } = useModalDeepLink();
  const [currentModalType, setCurrentModalType] = useState<ModalType>(ModalType.None);

  useEffect(() => {
    if (modalTypeFromUrl?.toLowerCase().includes(ModalType.Templates)) {
      setCurrentModalType(ModalType.Templates);
    }
  }, [modalTypeFromUrl]);

  const renderModal = () => {
    switch (currentModalType) {
      case ModalType.None:
        return null;

      case ModalType.Templates:
        return (
          <TemplatesWrapper
            onShowUpgradeModal={onShowUpgradeModal}
            setIsLoading={setIsLoading}
            handleModalAction={handleModalAction}
            currentCanvasDocumentId={currentCanvasDocumentId}
            onCreateTemplate={onCreateTemplate}
          />
        );
      default:
        return null;
    }
  };

  return renderModal();
}
