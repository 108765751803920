import { useState, useRef } from "react";
import { getPathPrefix } from "utils/getPathPrefix";
import { VisibleCursors } from "frontend/icons/visible-cursors";
import { HiddenCursors } from "frontend/icons/hidden-cursors";
import { useCanvasSettings } from "frontend/hooks/use-canvas-settings";
import { SyncService } from "frontend/services/syncService";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { syncServiceAtom } from "state-atoms";
import { useAtomValue } from "jotai";
import style from "./show-cursors-dropdown.module.css";
import { useOnClickOutside } from "usehooks-ts";

export default function CursorVisibilityDropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const syncService: SyncService<RW> = useAtomValue(syncServiceAtom);
  const { getSetting, updateSetting } = useCanvasSettings(syncService);

  useOnClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });

  const options = [
    {
      icon: <VisibleCursors />,
      text: "Show Cursors",
      value: true,
      data_testid: "show-cursors-option",
    },
    {
      icon: <HiddenCursors />,
      text: "Hide Cursors",
      value: false,
      data_testid: "hide-cursors-option",
    },
  ];

  function isSelected(option: any) {
    return getSetting("showCursors") === option.value;
  }

  const optionRenderer = (option: any) => {
    return (
      <div
        className={style.option}
        onClick={() => {
          updateSetting("showCursors", option.value);
          setIsOpen(false);
        }}
        key={option.type}
        data-testid={option.data_testid}
      >
        <div className={style.optionIcon}>{option.icon}</div>
        <span>{option.text}</span>
      </div>
    );
  };

  return (
    <div className={style.container} ref={dropdownRef}>
      <div className={style.buttonWrapper}>
        <div className={style.button} onClick={() => setIsOpen(!isOpen)} data-testid="cursor-visibility-button">
          {options.find(isSelected)?.icon}
          <div className={style.dropdownWrapper}>
            <img
              src={getPathPrefix("/images/chevron-blue.svg")}
              style={{
                transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)",
                transition: "transform 0.2s ease-in-out",
              }}
            />
          </div>
        </div>
        {isOpen && <div className={style.optionsContainer}>{options.map((option) => optionRenderer(option))}</div>}
      </div>
    </div>
  );
}
