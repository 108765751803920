import useSWR, { SWRConfiguration, Key } from 'swr';
import { PrimitiveAtom, useAtom } from 'jotai';
import { isEmpty } from "rambda";
import useStateValue from 'frontend/state/value';

type FetcherFn<Data> = (...args: any[]) => Promise<Data>;

interface FetchWithJotaiParams<Data> {
  key: Key;
  fetchFn: FetcherFn<Data>;
  atom: PrimitiveAtom<Data>;
  config?: SWRConfiguration;
}

export function useFetchWithJotai<Data>({
  key,
  fetchFn,
  atom,
  config,
}: FetchWithJotaiParams<Data>) {
  const [atomValue, setAtomValue] = useAtom(atom);

  const [{ isLoggedIn }] = useStateValue();

  const shouldFetch = !atomValue || isEmpty(atomValue) && isLoggedIn;

  const { data, error, isLoading } = useSWR(
    shouldFetch ? key : null,
    fetchFn,
    {
      ...config,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
      onSuccess: (data) => {
        setAtomValue(data);
      },
    }
  );

  return {
    data: atomValue || data,
    error,
    isLoading: shouldFetch && isLoading,
  };
}