import { useAtom } from "jotai";
import { useState, useCallback } from "react";
import { getUsersByIds, getUsersByPage } from "frontend/api";
import { useTeam } from "./use-team";
import { Team } from "shared/datamodel/schemas/team";
import { userCacheAtom, restrictedUserCacheAtom } from "state-atoms";
import { User, UsersCache, usersOnPage } from "shared/datamodel/schemas";
import { InstanceType } from "shared/datamodel/schemas/ability";
import { Project } from "shared/datamodel/schemas/project";
import { useProject } from "./use-project";
import consts from "shared/consts";
import useFeatureValue from "./use-features";

export default function useUsers(type: InstanceType, id: number | null) {
  const applyRestrictedLimitation = useFeatureValue(consts.FEATURE_NAMES.APPLY_VIEWER_RESTRICTED) === "true";
  const [usersCache, setUsersCache] = useAtom(userCacheAtom);
  const [restrictedUserCache, setRestrictedUserCache] = useAtom(restrictedUserCacheAtom);
  const [isLoading, setIsLoading] = useState(false);
  const { getTeam } = useTeam();
  const { getProjectById } = useProject();
  const key = `${type}-${id}`;

  const shouldNotFetch = (reload: boolean) => {
    const noMoreUsers = usersCache[key] && !usersCache[key].hasMore;
    return !reload && (isLoading || noMoreUsers);
  };

  const getInstanceUsers = async (instance: Team | Project): Promise<User[]> => {
    if (type === InstanceType.Team) {
      return await getUsersByIds((instance as Team).userTeamPermissions.map((user: any) => user.id));
    } else {
      return await getUsersByIds((instance as Project).userProjectPermissions.map((user: any) => user.id));
    }
  };

  const fetchUsers = async (reload: boolean) => {
    if (shouldNotFetch(reload)) return;
    setIsLoading(true);
    try {
      const pageByLimit = usersCache[key] ? Math.ceil(usersCache[key].users.length / usersOnPage) + 1 : 1;
      const prevPage = usersCache[key]?.page ?? 0;
      const page = reload ? 1 : Math.max(prevPage + 1, pageByLimit);
      const newUsers = await getUsersByPage(page);
      setUsersCache((prev: UsersCache) => ({
        ...prev,
        [key]: { users: reload ? newUsers : [...(prev[key]?.users || []), ...newUsers], page: page, hasMore: true, failed: false },
      }));
      if (applyRestrictedLimitation) {
        const newRestrictedUsers = newUsers.filter((user) => !user.isSubscribedToCanvas);
        setRestrictedUserCache({
          ...restrictedUserCache,
          [key]: reload ? newRestrictedUsers : [...(restrictedUserCache[key] || []), ...newRestrictedUsers],
        });
      }
      if (newUsers.length === 0) {
        setUsersCache((prev: UsersCache) => ({
          ...prev,
          [key]: { ...prev[key], hasMore: false },
        }));
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
      setUsersCache((prev: UsersCache) => ({
        ...prev,
        [key]: { ...prev[key], failed: true },
      }));
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInstanceUsers = async (instance: Team | Project, reload: boolean) => {
    if (shouldNotFetch(reload)) return;
    setIsLoading(true);
    try {
      const newUsers = await getInstanceUsers(instance);
      setUsersCache((prev: UsersCache) => ({
        ...prev,
        [key]: { users: newUsers, page: 1, hasMore: false , failed: false },
      }));
      if (applyRestrictedLimitation) {
        setRestrictedUserCache({
          ...restrictedUserCache,
          [key]: newUsers.filter((user) => !user.isSubscribedToCanvas),
        });
      }
      if (newUsers.length === 0) {
        setUsersCache((prev: UsersCache) => ({
          ...prev,
          [key]: { ...prev[key], hasMore: false },
        }));
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
      setUsersCache((prev: UsersCache) => ({
        ...prev,
        [key]: { ...prev[key], failed: true },
      }));
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const loadUsers = useCallback(async (reload: boolean = false) => {
    if (type === InstanceType.Team && id === null) {
      await fetchUsers(reload);
    } else if (type === InstanceType.Team && id) {
      const team = getTeam(id);
      if (team?.accountTeam) {
        await fetchUsers(reload);
      } else {
        if (team && (!usersCache[key] || reload)) {
          await fetchInstanceUsers(team, reload);
        }
      }
    }
    if (type === InstanceType.Project && id) {
      const project = getProjectById(id);
      if (project && (!usersCache[key] || reload)) {
        await fetchInstanceUsers(project, reload);
      }
    }
  }, [type, id, key, usersCache, getTeam, getProjectById]);

  return {
    users: usersCache[key]?.users || [],
    hasMore: usersCache[key]?.hasMore ?? true,
    failed: usersCache[key]?.failed ?? false,
    isLoading,
    loadUsers,
    reloadUsers: async () => await loadUsers(true),
  };
}