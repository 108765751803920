export enum PlatformType {
  Windows,
  Macintosh,
  iPhone,
  iPad,
  iPod,
  Android,
  Linux,
  Unknown,
}

let platformType: PlatformType;

export function getPlatformType() {
  if (platformType == undefined) {
    if ("userAgentData" in (navigator as any)) {
      const platform = (navigator as any).userAgentData.platform;
      if (platform == "macOS") platformType = PlatformType.Macintosh;
      else if (platform == "Windows") platformType = PlatformType.Windows;
      else if (platform == "Linux") platformType = PlatformType.Linux;
      else platformType = PlatformType.Unknown;
    } else {
      const userAgent = navigator.userAgent;
      if (userAgent.includes("Windows")) platformType = PlatformType.Windows;
      else if (userAgent.includes("Macintosh")) platformType = PlatformType.Macintosh;
      else if (userAgent.includes("iPhone")) platformType = PlatformType.iPhone;
      else if (userAgent.includes("iPad")) platformType = PlatformType.iPad;
      else if (userAgent.includes("iPod")) platformType = PlatformType.iPod;
      else if (userAgent.includes("Android"))
        // must test before linux, since android includes linux
        platformType = PlatformType.Android;
      else if (userAgent.includes("Linux")) platformType = PlatformType.Linux;
      else platformType = PlatformType.Unknown;
    }
  }
  return platformType;
}

export function getBrowser() {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "";
  }
  return browserName;
}

export function getQueryParam(name: string) {
  try {
    const url = window.location.href;
    const params = new URL(url).searchParams;
    return params.get(name);
  } catch (error) {
    return null;
  }
}
