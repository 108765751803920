import { CSSProperties } from 'react';
import style from './back-arrow.module.css';
export default function BackArrow({onClick, customStyle}: {onClick?: () => void, customStyle?: CSSProperties}) {
    function renderArrow() {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
            <path d="M1 5.5H12.2503" stroke="#0B2642" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 5.5L5.50011 10.0001" stroke="#0B2642" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 5.50011L5.50011 1" stroke="#0B2642" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        );
      }
      
    return (
        <div onClick={onClick} className={style.container} style={customStyle}>
          {renderArrow()}
          <span data-testid="back-on-checkout-page">Back</span>
        </div>
      );
}