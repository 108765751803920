import useOutsideRef from "frontend/utils/click-outside-handler";
import { useRef } from "react";
import style from "./error-modal.module.css";

export default function ErrorModal({
  title,
  children,
  onDismiss,
}: {
  title: string;
  children: any;
  onDismiss: () => void;
}) {
  const ref = useRef(null);
  useOutsideRef(ref, onDismiss);
  return (
    <div ref={ref} className={style.container}>
      <span className={style.title}>{title}</span>
      {children}
    </div>
  );
}
