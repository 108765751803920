import UpgradeModalGeneric, { UpgradeModalNeededProps } from "./upgrade-modal";
import img from "public/images/upgrade-modal-images/upgrade-for-orgchart-fields.png";

export default function UpgradeModalForOrgchartFields(props: UpgradeModalNeededProps) {
  return (
    <UpgradeModalGeneric
      trackingEvent="orgchart-fields"
      title={"Upgrade to add fields and information"}
      content={
        "Create your organization’s visual data base and keep your org chart up-to-date, filter to quickly see all members that fit your criteria."
      }
      bulletpoints={[
        "Blazing fast multiplayer",
        "Task management",
        "Commenting",
        "A world of templates",
        "Quality file export",
      ]}
      image={img.src}
      {...props}
    />
  );

}