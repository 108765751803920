import { useState } from "react";
import { StaticImageData } from "next/image";
import StyledButton from "frontend/ui-components/styled-button";
import { fileExt } from "frontend/utils/file-utils";
import style from "./styles.module.css";
import newStyle from "./new-style.module.css";
import cn from "classnames";
import item_view_with_image_png from "public/images/onboarding/orgchart_layout_screen/with_image.png";
import item_view_without_image_png from "public/images/onboarding/orgchart_layout_screen/without_image.png";
import item_view_with_image_webp from "public/images/onboarding/orgchart_layout_screen/with_image.webp";
import item_view_without_image_webp from "public/images/onboarding/orgchart_layout_screen/without_image.webp";

import bg_webp from "public/images/onboarding/orgchart_layout_screen/canvas-background.webp";
import bg_png from "public/images/onboarding/orgchart_layout_screen/canvas-background.png";

import orgchart_with_image_webp from "public/images/onboarding/orgchart_layout_screen/orgchart-image.webp";
import orgchart_with_image_png from "public/images/onboarding/orgchart_layout_screen/orgchart-image.png";

import orgchart_without_image_webp from "public/images/onboarding/orgchart_layout_screen/orgchart-no-image.webp";
import orgchart_without_image_png from "public/images/onboarding/orgchart_layout_screen/orgchart-no-image.png";

function Picture({
  srcset,
  alt,
  fallback,
  lazy = false,
}: {
  srcset: StaticImageData[];
  alt?: string;
  fallback?: StaticImageData;
  lazy?: boolean;
}) {
  fallback ??= srcset[srcset.length - 1];
  return (
    <picture>
      {srcset.map(({ src }) => (
        <source key={src} srcSet={src} type={`image/${fileExt(src)}`} />
      ))}
      <img
        src={fallback.src}
        alt={alt}
        loading={lazy ? "lazy" : "eager"}
        draggable={false}
        width={fallback.width}
        height={fallback.height}
      />
    </picture>
  );
}

export default function OnboardingOrgChartView({
  onCompleted,
}: {
  onCompleted: (v: "with-image" | "without-image") => void;
}) {
  const [selected, setSelected] = useState<"with-image" | "without-image">('with-image');

  return (
    <div className={cn(newStyle.onboarding, newStyle.twoColumns)}>
      <div className={newStyle.main}>
        <span className={style.title}>Select your org chart layout</span>
        <p style={{ placeSelf: "flex-start" }}>You can always change it later</p>

        <div className={style.blocks} style={{ minHeight: "190px" }}>
          <div
            className={cn(style.block, { [style.selected]: selected === "with-image" })}
            style={{ width: "250px", height: "190px", aspectRatio: "1" }}
            onClick={() => setSelected("with-image")}
          >
            <div className={style.imageContainer}>
              <Picture srcset={[item_view_with_image_webp, item_view_with_image_png]} alt="With photo" />
            </div>
            <div className={style.blockTitle}>
              <div className={cn(style.radio, { [style.selected]: selected === "with-image" })} />
              <span className={style.radioLabel}>With profile picture</span>
            </div>
          </div>
          <div
            className={cn(style.block, { [style.selected]: selected === "without-image" })}
            style={{ width: "250px", height: "190px", aspectRatio: "1" }}
            onClick={() => setSelected("without-image")}
          >
            <div className={style.imageContainer}>
              <Picture srcset={[item_view_without_image_webp, item_view_without_image_png]} alt="Without photo" />
            </div>
            <div className={style.blockTitle}>
              <div className={cn(style.radio, { [style.selected]: selected === "without-image" })} />
              <span className={style.radioLabel}>Without profile picture</span>
            </div>
          </div>
        </div>

        <StyledButton
          title={"Continue"}
          customStyle={{ width: "212px" }}
          enabled={selected != null}
          onClick={() => onCompleted(selected!)}
        />
      </div>

      <div className={newStyle.sidebar}>
        <div className={newStyle.imageContainer}>
          <Picture srcset={[bg_webp, bg_png]} />
          {selected == "with-image" && (
            <Picture srcset={[orgchart_with_image_webp, orgchart_with_image_png]} alt="Org chart with profile images" />
          )}
          {selected == "without-image" && (
            <Picture
              srcset={[orgchart_without_image_webp, orgchart_without_image_png]}
              alt="Org chart without profile images"
            />
          )}
        </div>
      </div>
    </div>
  );
}
