import UpgradeModalGeneric, { UpgradeModalNeededProps } from "./upgrade-modal";
import { getPathPrefix } from "../../utils/getPathPrefix";
import styles from "./upgrade-modal.module.css";

export default function UpgradeModalForGantt(props: UpgradeModalNeededProps) {
  return (
    <UpgradeModalGeneric
      trackingEvent="gantt"
      title="Upgrade for unlimited Gantt tasks"
      content="Manage projects effortlessly with Gantt charts: customize lanes, add unlimited tasks, and set dependencies seamlessly."
      bulletpoints={[
        "Unlimited Infinite canvases",
        "Blazing Fast Multiplayer",
        "Effortless Team Collaboration",
        "A world of templates",
        "Quality file export",
      ]}
      image={getPathPrefix("/images/upgrade-modal-images/upgrade-for-gantt.png")}
      cancelLineText={
        <>
          You may <b>cancel your trial</b> any time
        </>
      }
      imageClassName={styles.newUpgradeImage}
      {...props}
    />
  );
}
