import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { checkCustomerPaymentMethod, createSetupIntent } from "frontend/api";
import { useCustomerPortal } from "frontend/billingUtils";
import { PortalID } from "frontend/modal/usePortal";
import tracking from "frontend/tracking";
import DropdownPicker from "frontend/ui-components/picker/dropdown-picker";
import { CloseIcon } from "frontend/ui-components/svg-shapes";
import { numberWithCommas } from "frontend/utils/math-utils";
import dynamic from "next/dynamic";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import consts, { MIN_PAID_SEATS, Plan } from "shared/consts";
import { InvoicePreview, User } from "shared/datamodel/schemas";
import BackArrow from "./back-arrow";
import { BillingPeriod } from "./billing-period-picker";
import { CheckoutSource } from "./checkout";
import style from "./payment-page.module.css";
import { addDays } from "shared/util/date-utils";
import { useAtomValue } from "jotai";
import { accountSubscribedCountAtom, invoicePreviewsAtom } from "state-atoms";
import classNames from "classnames";
import useFeatureValue from "frontend/hooks/use-features";
import { ErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/nextjs";
import { ModalErrorPage } from "frontend/error/error-page";
import { getPathPrefix } from "../utils/getPathPrefix";

const stripePromise = loadStripe(process.env.STRIPE_PKEY!);

const CheckoutForm = dynamic(() => import("./checkout-form"));

export default function PaymentPage({
  seatsCount,
  trialDays,
  priceByPlan,
  user,
  source,
  onDismiss,
}: {
  seatsCount: number;
  trialDays: number;
  priceByPlan: Record<Plan, Record<BillingPeriod, number>>;
  user: User;
  source?: CheckoutSource;
  onDismiss?: () => void;
}) {
  //atoms
  const invoicePreviewsAtomValue = useAtomValue(invoicePreviewsAtom);
  const applyRestrictedLimitation = useFeatureValue(consts.FEATURE_NAMES.APPLY_VIEWER_RESTRICTED) === "true";
  seatsCount = applyRestrictedLimitation ? useAtomValue(accountSubscribedCountAtom) : seatsCount;
  const [selectedPeriod, setSelectedPeriod] = useState<BillingPeriod>("year");
  const [invoiceByPlanCache, setInvoiceByPlanCache] = useState<Record<
    Plan,
    Record<BillingPeriod, InvoicePreview>
  > | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<Plan>(Plan.pro);
  const [yearlySaving, setYearlySaving] = useState<number>(0);
  const [seatsToBuy, setSeatsToBuy] = useState(seatsCount < MIN_PAID_SEATS ? MIN_PAID_SEATS : seatsCount);
  const [showMinSeatsMessage, setShowMinSeatsMessage] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [stripeSecret, setStripeSecret] = useState<string | null>(null);
  const [confirmPayment, setcConfirmPayment] = useState<boolean>(false);
  const [hasPaymentMethod, setHasPaymentMethod] = useState<boolean>(false);
  const [showCheckout, setShowCheckout] = useState<boolean>(false);
  const [dropDownOptions, setDropDownOptions] = useState<number[]>([]);
  const [seatsMultiplyFactor, setSeatsMultiplyFactor] = useState<number>(1); //We calcualte client-side the subtotal of the invoice every time the user changes the number of seats. We do it by multiplying the base number by the delta of the seats after the change.
  const [message, setMessage] = useState<string | null>(null);

  const showCheckoutForm = showCheckout && stripeSecret;
  const isDowngraded = user?.planInfo?.is_downgraded;

  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "paymet-page-showed", source);
    async function checkPaymentMethod() {
      const hasPaymentMethod = await checkCustomerPaymentMethod();
      setHasPaymentMethod(hasPaymentMethod);
    }
    checkPaymentMethod();
    const checkoutContainer = document.getElementById(PortalID.Checkout);
    if (checkoutContainer) {
      checkoutContainer.style.display = "flex";
    }
    return () => {
      if (checkoutContainer) {
        checkoutContainer.style.display = "none";

        const backgroundModalContainer = document.getElementById(PortalID.Modal);
        if (backgroundModalContainer && backgroundModalContainer.children.length >= 1) {
          const backgroundModal = backgroundModalContainer.children[0];
          backgroundModalContainer.removeChild(backgroundModal);
        }
      }
    };
  }, []);

  useEffect(() => {
    const forceCacheInvalidation = true;
    previewInvoice(forceCacheInvalidation);
    setSeatsMultiplyFactor(Math.max(MIN_PAID_SEATS, seatsToBuy) / Math.max(MIN_PAID_SEATS, seatsCount));
  }, [seatsToBuy, seatsCount]);

  useEffect(() => {
    previewInvoice();
    if (invoiceByPlanCache && invoiceByPlanCache[selectedPlan] && invoiceByPlanCache[selectedPlan][selectedPeriod]) {
      const monthlyPlan = invoiceByPlanCache[selectedPlan]["month"];
      const yearlyPlan = invoiceByPlanCache[selectedPlan]["year"];
      const yearlySaving = (yearlyPlan.dueNextPeriod / (monthlyPlan.dueNextPeriod * 12)) * 100;
      setYearlySaving(yearlySaving);
    }
  }, [invoiceByPlanCache]);

  useEffect(() => {
    const planInvoicePreview = invoiceByPlanCache && invoiceByPlanCache[selectedPlan][selectedPeriod];
    console.log({ seatsToBuy, trialDays, planInvoicePreview, yearlySaving });
  }, [invoiceByPlanCache, yearlySaving]);

  useEffect(() => {
    if (seatsCount > 0) {
      const dropDownOptions = getDropDownOptions(seatsCount < MIN_PAID_SEATS ? MIN_PAID_SEATS : seatsCount, 1, 50);
      setDropDownOptions(dropDownOptions);
    }
    if (seatsCount < MIN_PAID_SEATS) {
      setSeatsToBuy(MIN_PAID_SEATS);
    } else {
      setSeatsToBuy(seatsCount);
    }
  }, [seatsCount]);

  useEffect(() => {
    previewInvoice();
    if (invoicePreviewsAtomValue) {
      tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "payment-page-invoice-previews-loaded", source);
    }
  }, [invoicePreviewsAtomValue]);

  async function previewInvoice(invalidateCache?: boolean) {
    if (invalidateCache) {
      setInvoiceByPlanCache(null);
    }
    //if invoice for plan not found in state cache, update it
    if (
      invalidateCache ||
      (invoicePreviewsAtomValue &&
        seatsToBuy > 0 &&
        (!invoiceByPlanCache || (invoiceByPlanCache && !invoiceByPlanCache[selectedPlan])))
    ) {
      /*const paidPlans = Object.values(Plan)
        .filter((i) => isNaN(parseInt(i as string)))
        .filter((i) => i != planToString(Plan.basic)?.toLowerCase());
      let invoicePromises: Promise<any>[] = [];
      for (const plan of paidPlans) {
        for (const period of ["month", "year"]) {
          const invoicePromise = retrieveUpcomingInvoicePreview(
            stringToPlan(plan as string)!,
            seatsToBuy,
            period as any
          );
          invoicePromises.push(invoicePromise);
        }
      }

      const invoices = await Promise.all(invoicePromises);*/
      const invoices = invoicePreviewsAtomValue;
      if (!invoices) {
        return;
      }
      const proInvoicePreview = invoices.find((i) => i.plan === Plan.pro && i.interval === "month");
      const teamInvoicePreview = invoices.find((i) => i.plan === Plan.team && i.interval === "month");
      const proYearlyInvoicePreview = invoices.find((i) => i.plan === Plan.pro && i.interval === "year");
      const teamYearlyInvoicePreview = invoices.find((i) => i.plan === Plan.team && i.interval === "year");

      if (!proInvoicePreview || !teamInvoicePreview || !proYearlyInvoicePreview || !teamYearlyInvoicePreview) {
        return;
      }

      let updatedInvoice: any = {};
      updatedInvoice[Plan.pro] = {
        month: {
          dueToday: proInvoicePreview.dueToday,
          dueNextPeriod: proInvoicePreview.dueNextPeriod,
          nextPeriod: proInvoicePreview.nextPeriod,
          trialEndDate: proInvoicePreview.trialEndDate,
        } as InvoicePreview,
        year: {
          dueToday: proYearlyInvoicePreview.dueToday,
          dueNextPeriod: proYearlyInvoicePreview.dueNextPeriod,
          nextPeriod: proYearlyInvoicePreview.nextPeriod,
          trialEndDate: proYearlyInvoicePreview.trialEndDate,
        } as InvoicePreview,
      };
      updatedInvoice[Plan.team] = {
        month: {
          dueToday: teamInvoicePreview.dueToday,
          dueNextPeriod: teamInvoicePreview.dueNextPeriod,
          nextPeriod: teamInvoicePreview.nextPeriod,
          trialEndDate: teamInvoicePreview.trialEndDate,
        } as InvoicePreview,
        year: {
          dueToday: teamYearlyInvoicePreview.dueToday,
          dueNextPeriod: teamYearlyInvoicePreview.dueNextPeriod,
          nextPeriod: teamYearlyInvoicePreview.nextPeriod,
          trialEndDate: teamYearlyInvoicePreview.trialEndDate,
        } as InvoicePreview,
      };

      setInvoiceByPlanCache(updatedInvoice);
    }
  }

  function renderSkeleton() {
    return (
      <React.Fragment>
        <Skeleton count={1} width={"546px"} height={"57px"} />
      </React.Fragment>
    );
  }

  function renderOption(interval: "year" | "month") {
    const planInvoicePreview = invoiceByPlanCache && invoiceByPlanCache[selectedPlan][interval];
    const pricePerSeat = priceByPlan[selectedPlan][interval];
    const planPrice = pricePerSeat ? `$${numberWithCommas(parseFloat(pricePerSeat.toFixed(2)))}` : "";
    const usersCountText = `${numberWithCommas(seatsToBuy)} ${seatsToBuy > 1 ? "users" : "user"}`;

    const isSelected = selectedPeriod === interval;
    const yearlyText = isDowngraded ? `${planPrice} a month` : `${trialDays} days free, then ${planPrice} a month`;
    const monthlyText = `${planPrice} a month`;
    const showDiscount = interval === "year";

    const text = showDiscount ? yearlyText : monthlyText;

    return (
      <div key={interval} className={style.optionWrapper}>
        {!invoicePreviewsAtomValue ? (
          <Skeleton width={94} containerClassName={style.planDescription} />
        ) : (
          <span className={style.planDescription}>{`paid ${interval}ly`}</span>
        )}
        {!invoicePreviewsAtomValue ? (
          renderSkeleton()
        ) : (
          <div
            key={interval}
            className={isSelected ? style.selectedOption : style.option}
            onClick={() => setSelectedPeriod(interval)}
          >
            <React.Fragment>
              {showDiscount && <div className={style.discountLabel}>{`Save ${(100 - yearlySaving).toFixed(0)}%`}</div>}
              <div className={isSelected ? style.selectedOptionButton : style.optionButton}>
                {isSelected && <div className={style.internalSelectedOptionButton} />}
              </div>
              <div className={style.optionTextContainer}>
                <span className={style.optionText}>{`${text}`}</span>
                {false && (
                  <span className={style.optionDetailsText}>{`($${pricePerSeat}/month x ${usersCountText})`}</span>
                )}
              </div>
            </React.Fragment>
          </div>
        )}
      </div>
    );
  }

  function renderMinimumSeatsMessage() {
    return (
      <div className={style.minSeatsContainer}>
        <span>{`The minium seats to purchase on the pro plan is ${MIN_PAID_SEATS}`}</span>
      </div>
    );
  }

  function getDropDownOptions(minValue: number, increment: number, length: number) {
    let arr = [];
    for (let i = 0; i < length; i++) {
      arr.push(minValue + i * increment);
    }
    return arr;
  }

  async function onPayButtonClicked() {
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "trial-modal-continue-clicked", selectedPlan);
    const subscriptionValue = invoiceByPlanCache ? invoiceByPlanCache[selectedPlan][selectedPeriod].dueNextPeriod : 0;
    tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.PAID_PLAN_SELECTED, subscriptionValue);
    const planInvoicePreview = invoiceByPlanCache && invoiceByPlanCache[selectedPlan][selectedPeriod];
    setShowCheckout(true);
    setLoading(true);
    if (
      hasPaymentMethod ||
      (planInvoicePreview &&
        !planInvoicePreview.trialEndDate &&
        planInvoicePreview.dueToday < consts.MIN_STRIPE_ALLOWED_AMOUNT)
    ) {
      //if we do not charge the customer or he already has payment method, let stripe's customer portal handle it. We should remove it once we will have a full-blow support for all billing scnearios
      useCustomerPortal({ type: "upgrade", planId: selectedPlan, interval: selectedPeriod });
    } else {
      //const secret = await createPaymentIntent(selectedPlan, seatsCount);
      const secret = await createSetupIntent({
        newPlan: selectedPlan,
        seatsCount: seatsToBuy,
        billingInterval: selectedPeriod,
      });
      setStripeSecret(secret);
      setLoading(false);
    }
  }

  function renderPayButton() {
    if (!stripeSecret) {
      return <Skeleton style={{ marginTop: "37px" }} count={1} width={212} height={36} />;
    }

    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "payment-button-rendered", selectedPlan);
    return (
      <div className={style.completePurchaseButtonContainer}>
        <button
          onClick={() => {
            confirmPayment ? {} : setcConfirmPayment(true);
            tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "complete-purchase-clicked", selectedPlan);
          }}
          className={classNames(style.continueButton, style.completePurchaseButton)}
        >
          {confirmPayment ? "Loading..." : "Complete purchase"}
        </button>
        {message && <span className={style.paymentMessage}>{message}</span>}
      </div>
    );
  }

  function renderPaymentForm() {
    return (
      <Elements options={{ clientSecret: stripeSecret!, appearance: { theme: "stripe" } }} stripe={stripePromise}>
        <CheckoutForm
          confirmPayment={confirmPayment}
          onLoadingChanged={(isLoading) => {
            if (!isLoading) {
              setcConfirmPayment(false);
            }
          }}
          onError={(error) => {
            Sentry.addBreadcrumb({
              message: "Error rendering checkout form (page)",
              category: "error",
              level: "error",
            });
            Sentry.captureException(error, { tags: { type: "billing", page: "checkout-form" } });
            console.error("Stripe error", error);
          }}
          user={user}
          customStyle={{ padding: "40px" }}
          amount={invoiceByPlanCache ? invoiceByPlanCache[selectedPlan][selectedPeriod].dueNextPeriod : 0} //track the subscription's non-prorated value
          message={message}
          setMessage={setMessage}
        />
      </Elements>
    );
  }

  function renderCheckoutSkeleton() {
    return (
      <div className={style.checkoutSkeletonContainer}>
        <Skeleton containerClassName={style.checkoutSkeletonContainer} count={4} width={460} height={44} />
      </div>
    );
  }

  function renderCheckout() {
    return !stripeSecret ? (
      renderCheckoutSkeleton()
    ) : (
      <div className={style.checkoutContainer}>{renderPaymentForm()}</div>
    );
  }

  function renderStepper() {
    return (
      <div className={style.stepperContainer}>
        <div className={showCheckoutForm ? style.step : style.currentstep} />
        <div className={showCheckoutForm ? style.currentstep : style.step} />
      </div>
    );
  }

  function renderSubTotalLine(subTotalTitle: string, subTotalDescription: string, planPrice: string) {
    return (
      <div className={style.subTotalContainer}>
        <div className={style.subTotalDetails}>
          <span className={style.subTotalTitle}>{subTotalTitle}</span>
          <span className={style.optionDetailsText}>{subTotalDescription}</span>
        </div>
        <span className={style.subTotalPrice}>{planPrice}</span>
      </div>
    );
  }

  function renderCancellationNote(formattedDate: string) {
    return (
      <div className={style.cancellationNoteContainer}>
        <img src={getPathPrefix("/images/info-icon.svg")} />
        <span className={style.cancellationNoteText}>
          {isDowngraded ? `Cancel any time.` : `Cancel any time. You won’t be charged until ${formattedDate}.`}
        </span>
      </div>
    );
  }

  function renderSubTotal() {
    const pricePerSeat = priceByPlan[selectedPlan][selectedPeriod];
    const usersCountText = `${numberWithCommas(seatsToBuy)} ${seatsToBuy > 1 ? "users" : "user"}`;
    const planInvoicePreview = invoiceByPlanCache && invoiceByPlanCache[selectedPlan][selectedPeriod];
    console.log("renderSubTotal planInvoicePreview", planInvoicePreview);
    const invoicePreview = planInvoicePreview
      ? selectedPeriod === "year"
        ? planInvoicePreview!.dueToday / 12
        : planInvoicePreview!.dueToday
      : 0;
    const planPrice = invoicePreview
      ? `$${numberWithCommas(parseFloat((invoicePreview * seatsMultiplyFactor).toFixed(2)))} per month`
      : "";
    const subTotalDescription = `($${pricePerSeat} / month x ${usersCountText})`;

    return (
      <div className={style.subTotalLines}>
        {!invoicePreviewsAtomValue ? (
          <Skeleton count={2} width={546} height={24} />
        ) : (
          <React.Fragment>
            {false && selectedPeriod === "year" && renderSubTotalLine("Today's Total", "", "$0")}
            {renderSubTotalLine(`Subtotal`, subTotalDescription, planPrice || "$0")}
          </React.Fragment>
        )}
      </div>
    );
  }

  function renderPlanSelection() {
    const planInvoicePreview = invoiceByPlanCache && invoiceByPlanCache[selectedPlan][selectedPeriod];
    //const trialEndDate = planInvoicePreview && planInvoicePreview!.trialEndDate ? new Date(planInvoicePreview!.trialEndDate! * 1000) : new Date();
    const now = new Date();
    const trialEndDate = new Date(addDays(now, trialDays) * 1000);
    const nextPeriodFormatedDate = trialEndDate.toDateString().split(" ").slice(2); //remove the day part of the date
    const newPeriodMonthName = trialEndDate.toLocaleString("default", { month: "long" });
    const formattedTrialEndDate = `${newPeriodMonthName} ${nextPeriodFormatedDate[0]}, ${nextPeriodFormatedDate[1]}`;

    return (
      <React.Fragment>
        <div className={style.optionsWrapper}>
          <div className={style.seatsContainer}>
            <span>How many seats for your team?</span>
            <div className={style.dropDownContainer}>
              <DropdownPicker<number>
                options={dropDownOptions}
                titles={(seatsCount) => `${seatsCount}`}
                optionRenderer={(seatsCount) => {
                  return { element: <span>{`${seatsCount.option} seats`}</span>, enabled: true };
                }}
                onChange={(selectedSeats) => setSeatsToBuy(selectedSeats)}
                isSelected={(v) => v === seatsToBuy}
                enabled={true}
                scrollToSelected={true}
                pickerCustomIcon={getPathPrefix("/images/chevron-black.svg")}
              />
            </div>
          </div>
          <div className={style.plansSelectionContainer}>
            <div className={style.options}>
              {["month", "year"].map((interval) => {
                return renderOption(interval as "year" | "month");
              })}
            </div>
            {!invoicePreviewsAtomValue ? (
              <Skeleton width={546} height={44} />
            ) : (
              selectedPeriod === "year" && renderCancellationNote(formattedTrialEndDate)
            )}
          </div>
          {false && (
            <div className={style.secureCheckout}>
              <img src={getPathPrefix("/images/checkout/lock.svg")} />
              <span>Secure checkout, cancel anytime</span>
            </div>
          )}
        </div>
        <div className={style.footer}>
          {renderSubTotal()}
          {showMinSeatsMessage && renderMinimumSeatsMessage()}
        </div>
      </React.Fragment>
    );
  }

  function renderCtaButton() {
    return (
      <div className={style.ctaButton}>
        {!invoicePreviewsAtomValue ? (
          <Skeleton width={212} height={36} />
        ) : (
          <button onClick={onPayButtonClicked} className={style.continueButton}>
            {`${loading ? "Loading..." : "Continue"}`}
          </button>
        )}
      </div>
    );
  }

  function renderTitle() {
    const isDowngraded = user?.planInfo?.is_downgraded;
    return (
      <span className={style.title}>{!isDowngraded ? "Try WorkCanvas Pro for free" : "Upgrade To WorkCanvas Pro"}</span>
    );
  }

  function renderMainLayout() {
    const rightSectionText =
      source?.name === "templates"
        ? `Unlock ${source.value} and 100+ premium templates`
        : "Unlock Pro with 100+ premium templates";
    return (
      <div className={style.container}>
        <div className={style.content}>
          <div className={style.leftSection}>
            <ErrorBoundary
              fallback={<ModalErrorPage />}
              onError={(error, info) => {
                tracking.trackEvent(
                  consts.TRACKING_CATEGORY.BILLING,
                  "payment-page-error",
                  error.message,
                  source?.value
                );
                console.error("Payment error", error, info);
                Sentry.addBreadcrumb({
                  message: "Error rendering checkout form (page)",
                  category: "error",
                  level: "error",
                  data: { info, source },
                });
                Sentry.captureException(error, { tags: { type: "billing", page: "payment-page" } });
              }}
            >
              {showCheckoutForm ? (
                <BackArrow
                  onClick={() => {
                    setShowCheckout(false);
                    setMessage(null);
                  }}
                  customStyle={{ position: "absolute", top: "10px", left: "16px" }}
                />
              ) : (
                <CloseIcon
                  onClick={onDismiss}
                  style={{ position: "absolute", top: "27px", left: "27px", cursor: "pointer" }}
                />
              )}
              {showCheckout ? (
                <div className={style.checkoutContent}>
                  {renderTitle()}
                  {renderCheckout()}
                </div>
              ) : (
                <div className={style.choosePlanContent}>
                  {renderTitle()}
                  {renderPlanSelection()}
                </div>
              )}
              {showCheckout ? renderPayButton() : renderCtaButton()}
            </ErrorBoundary>
          </div>
          <div className={style.rightSection}>
            <div className={style.rightSectionContent}>
              <span className={style.rightSectionTitle}>{rightSectionText}</span>
              <img className={style.image} src={getPathPrefix("/images/checkout/checkout-templates.svg")} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return renderMainLayout();
}
