import UpgradeModalGeneric, { UpgradeModalNeededProps } from "./upgrade-modal";
import img from "public/images/upgrade-modal-images/upgrade-for-orgchart-nodes.png";

export default function UpgradeModalForOrgchartNodes(props: UpgradeModalNeededProps) {
  return (
    <UpgradeModalGeneric
      trackingEvent="orgchart-nodes"
      title={"Upgrade to add unlimited teammates"}
      content={"Grow your Org Chart, visualize employee information and reporting relationships in your organization."}
      bulletpoints={[
        "Blazing fast multiplayer",
        "Task management",
        "Commenting",
        "A world of templates",
        "Quality file export",
      ]}
      image={img.src}
      {...props}
    />
  );

}