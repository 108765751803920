import { useEffect } from "react";
import BigModalContainer from "../big-modal-container";
import OnboardingTeamSize from "./team-size";
import InviteView from "./invite-view";
import { markTipSeen } from "../../api";
import { TipName } from "shared/datamodel/schemas/tip";
import { useAtom } from "jotai";
import { userTipsAtom } from "state-atoms/users-atoms";
import mixpanel from "mixpanel-browser";
import tracking from "../../tracking";
import consts from "shared/consts";
import useUrlQueryParamValue from "../../hooks/use-url-query-param";
import OnboardingOrgChartView from "./orgchart-view";
import { OrgChartViewType } from "shared/datamodel/schemas/org-chart";

export default function OnboardingFlow({ fromTemplate }: { fromTemplate?: string | null }) {
  const [tips, setUserTips] = useAtom(userTipsAtom);

  const [, setStepString] = useUrlQueryParamValue("step", { replace: true });

  let steps = [TipName.onboardingTeamSize, TipName.onboardingInvite];

  steps = steps.filter((step) => !tips.has(step));

  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.PAGE_VIEWS, "onboarding_flow_started");
    return () => {
      tracking.trackEvent(consts.TRACKING_CATEGORY.ONBOARDING, "onboarding_flow_finished");
      setStepString(null);
    };
  }, []);

  async function teamSizeSelected(teamSize: "myself" | "team") {
    if (teamSize === "team") {
      tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.TEAM_SIGNUP);
    }
    markTipSeen(TipName.onboardingTeamSize);
    mixpanel.register({ team_size: teamSize });
    tracking.trackEvent(consts.TRACKING_CATEGORY.ONBOARDING, "team_size_selected", teamSize);
    setUserTips((tips) => new Set([...tips, TipName.onboardingTeamSize]));
  }

  async function inviteCompleted() {
    markTipSeen(TipName.onboardingInvite);
    setUserTips((tips) => new Set([...tips, TipName.onboardingInvite]));
  }

  async function templateOnboardingDone(selection: "with-image" | "without-image") {
    let viewType = selection == "with-image" ? OrgChartViewType.Default : OrgChartViewType.NoProfilePicture;
    sessionStorage.setItem(
      consts.SESSION_STORAGE_KEYS.TEMPLATE_CHOSEN_OPTIONS,
      JSON.stringify({ orgChart: { viewType } }, null, 2)
    );
    markTipSeen(TipName.onboardingChosenTemplate);
    setUserTips((tips) => new Set([...tips, TipName.onboardingChosenTemplate]));
    tracking.trackEvent(consts.TRACKING_CATEGORY.ONBOARDING, "template_onboarding_finished", fromTemplate, {
      choices: selection,
    });
  }

  function renderStep(step: number): React.ReactNode {
    const tip = steps[step];
    setStepString(tip);
    switch (tip) {
      case TipName.onboardingTeamSize:
        return <OnboardingTeamSize onCompleted={teamSizeSelected} />;
      case TipName.onboardingChosenTemplate:
        const orgchartTemplateId = process.env.ORGCHART_TEMPLATE_ID;
        if (fromTemplate == orgchartTemplateId) {
          return <OnboardingOrgChartView onCompleted={templateOnboardingDone} />;
        }
        markTipSeen(TipName.onboardingChosenTemplate);
        setUserTips((tips) => new Set([...tips, TipName.onboardingChosenTemplate]));
        return null;
      case TipName.onboardingInvite:
        return <InviteView onCompleted={inviteCompleted} />;
    }
    return null;
  }

  return <BigModalContainer>{renderStep(0)}</BigModalContainer>;
}
