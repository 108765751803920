import React, { CSSProperties, useEffect, useRef, useState } from "react";
import style from "./payment-modal-no-trial.module.css";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import consts, { MIN_PAID_SEATS, Plan } from "shared/consts";
import { useCustomerPortal } from "frontend/billingUtils";
import { checkCustomerPaymentMethod, createSetupIntent } from "frontend/api";
import { CloseIcon } from "frontend/ui-components/svg-shapes";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import tracking from "frontend/tracking";
import BillingPeriodPicker, { BillingPeriod } from "../billing-period-picker";
import { planToString } from "shared/util/billing";
import { numberWithCommas } from "frontend/utils/math-utils";
import { BillingInterval, InvoicePreview } from "shared/datamodel/schemas/billing";
import { User } from "shared/datamodel/schemas";
import BackArrow from "../back-arrow";
import DropdownPicker from "frontend/ui-components/picker/dropdown-picker";
import dynamic from "next/dynamic";
import { CheckoutSource } from "../checkout";
import { useAtomValue } from "jotai";
import { accountSubscribedCountAtom, invoicePreviewsAtom } from "state-atoms";
import classNames from "classnames";
import useFeatureValue from "frontend/hooks/use-features";
import { ErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/nextjs";
import { getPathPrefix } from "../../utils/getPathPrefix";

type FeatureInfo = {
  text: string;
  isHighlighted?: boolean;
};

const stripePromise = loadStripe(process.env.STRIPE_PKEY!);

const CheckoutForm = dynamic(() => import("../checkout-form"));

export default function PaymentModalNoTrial({
  currentPlan,
  currentInterval,
  seatsCount,
  priceByPlan,
  user,
  onDismiss,
  source,
}: {
  currentPlan: Plan;
  currentInterval: BillingInterval;
  seatsCount: number;
  priceByPlan: Record<Plan, Record<BillingPeriod, number>>;
  user: User;
  onDismiss: () => void;
  source?: CheckoutSource;
}) {
  const applyRestrictedLimitation = useFeatureValue(consts.FEATURE_NAMES.APPLY_VIEWER_RESTRICTED) === "true";
  seatsCount = applyRestrictedLimitation ? useAtomValue(accountSubscribedCountAtom) : seatsCount;

  const featuresByPlan: Record<Plan, FeatureInfo[]> = { "1": [{ text: "" }], "2": [{ text: "" }], "3": [{ text: "" }] };

  featuresByPlan[Plan.team] = [
    { text: "Access to all templates" },
    { text: "Unlimited infinite canvases" },
    { text: "Public sharing" },
    { text: "Unlimited file uploads" },
    { text: "30 collaborators per canvas" },
  ];
  featuresByPlan[Plan.pro] = [
    { text: "Everything in team, plus:" },
    { text: "Export without watermark" },
    { text: "30+ collaborators per canvas" },
    { text: "Premium support" },
  ];
  //atoms
  const invoicePreviewsAtomValue = useAtomValue(invoicePreviewsAtom);

  const [selectedPeriod, setSelectedPeriod] = useState<BillingPeriod>("year");
  const [stripeSecret, setStripeSecret] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasPaymentMethod, setHasPaymentMethod] = useState<boolean>(false);
  const [confirmPayment, setcConfirmPayment] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<Plan>(Plan.pro);
  const [showPaymentPage, setShowPaymentPage] = useState<boolean>(false);
  const [invoiceByPlanCache, setInvoiceByPlanCache] = useState<Record<
    Plan,
    Record<BillingPeriod, InvoicePreview>
  > | null>(null);
  const [yearlySaving, setYearlySaving] = useState<number>(0);
  const [dropDownOptions, setDropDownOptions] = useState<number[]>([]);
  const [seatsToBuy, setSeatsToBuy] = useState(seatsCount < MIN_PAID_SEATS ? MIN_PAID_SEATS : seatsCount);
  const [seatsMultiplyFactor, setSeatsMultiplyFactor] = useState<number>(1); //We calcualte client-side the subtotal of the invoice every time the user changes the number of seats. We do it by multiplying the base number by the delta of the seats after the change.
  const [message, setMessage] = useState<string | null>(null);

  const ref = useRef<any>(null);
  const showPaymentForm = showPaymentPage; //stripeSecret && showPaymentPage;

  useEffect(() => {
    async function checkPaymentMethod() {
      const hasPaymentMethod = await checkCustomerPaymentMethod();
      setHasPaymentMethod(hasPaymentMethod);
    }
    checkPaymentMethod();
    const app = document.getElementById("__next");
    if (app) {
      app!.style.filter = "blur(4px)";
    }
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "paymet-modal-showed", currentPlan);

    return () => {
      if (app) {
        app!.style.filter = "unset";
      }
    };
  }, []);

  useEffect(() => {
    if (seatsCount > 0) {
      const dropDownOptions = getDropDownOptions(seatsCount < MIN_PAID_SEATS ? MIN_PAID_SEATS : seatsCount, 1, 50);
      setDropDownOptions(dropDownOptions);
    }
    if (seatsCount < MIN_PAID_SEATS) {
      setSeatsToBuy(MIN_PAID_SEATS);
    } else {
      setSeatsToBuy(seatsCount);
    }
  }, [seatsCount]);

  useEffect(() => {
    const forceCacheInvalidation = true;
    previewInvoice(forceCacheInvalidation);
    setSeatsMultiplyFactor(Math.max(MIN_PAID_SEATS, seatsToBuy) / Math.max(MIN_PAID_SEATS, seatsCount));
  }, [seatsToBuy, seatsCount]);

  useEffect(() => {
    previewInvoice();
    if (invoiceByPlanCache && invoiceByPlanCache[selectedPlan] && invoiceByPlanCache[selectedPlan][selectedPeriod]) {
      const monthlyPlan = invoiceByPlanCache[selectedPlan]["month"];
      const yearlyPlan = invoiceByPlanCache[selectedPlan]["year"];
      const yearlySaving =
        monthlyPlan.dueNextPeriod * 12 * seatsMultiplyFactor - yearlyPlan.dueNextPeriod * seatsMultiplyFactor;
      setYearlySaving(yearlySaving);
    }
  }, [invoiceByPlanCache, selectedPlan, seatsMultiplyFactor]);

  useEffect(() => {
    previewInvoice();
    if (invoicePreviewsAtomValue) {
      tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "payment-modal-invoice-previews-loaded", source);
    }
  }, [invoicePreviewsAtomValue]);

  async function previewInvoice(invalidateCache?: boolean) {
    if (invalidateCache) {
      setInvoiceByPlanCache(null);
    }
    //if invoice for plan not found in state cache, update it
    if (
      invalidateCache ||
      (invoicePreviewsAtomValue &&
        seatsToBuy > 0 &&
        (!invoiceByPlanCache || (invoiceByPlanCache && !invoiceByPlanCache[selectedPlan])))
    ) {
      const invoices = invoicePreviewsAtomValue;
      if (!invoices) {
        return;
      }
      const proInvoicePreview = invoices.find((i) => i.plan === Plan.pro && i.interval === "month");
      const teamInvoicePreview = invoices.find((i) => i.plan === Plan.team && i.interval === "month");
      const proYearlyInvoicePreview = invoices.find((i) => i.plan === Plan.pro && i.interval === "year");
      const teamYearlyInvoicePreview = invoices.find((i) => i.plan === Plan.team && i.interval === "year");

      if (!proInvoicePreview || !teamInvoicePreview || !proYearlyInvoicePreview || !teamYearlyInvoicePreview) {
        return;
      }

      let updatedInvoice: any = {};
      updatedInvoice[Plan.pro] = {
        month: {
          dueToday: proInvoicePreview.dueToday,
          dueNextPeriod: proInvoicePreview.dueNextPeriod,
          nextPeriod: proInvoicePreview.nextPeriod,
          trialEndDate: proInvoicePreview.trialEndDate,
        } as InvoicePreview,
        year: {
          dueToday: proYearlyInvoicePreview.dueToday,
          dueNextPeriod: proYearlyInvoicePreview.dueNextPeriod,
          nextPeriod: proYearlyInvoicePreview.nextPeriod,
          trialEndDate: proYearlyInvoicePreview.trialEndDate,
        } as InvoicePreview,
      };
      updatedInvoice[Plan.team] = {
        month: {
          dueToday: teamInvoicePreview.dueToday,
          dueNextPeriod: teamInvoicePreview.dueNextPeriod,
          nextPeriod: teamInvoicePreview.nextPeriod,
          trialEndDate: teamInvoicePreview.trialEndDate,
        } as InvoicePreview,
        year: {
          dueToday: teamYearlyInvoicePreview.dueToday,
          dueNextPeriod: teamYearlyInvoicePreview.dueNextPeriod,
          nextPeriod: teamYearlyInvoicePreview.nextPeriod,
          trialEndDate: teamYearlyInvoicePreview.trialEndDate,
        } as InvoicePreview,
      };

      setInvoiceByPlanCache(updatedInvoice);
    }
  }

  function getDropDownOptions(minValue: number, increment: number, length: number) {
    let arr = [];
    for (let i = 0; i < length; i++) {
      arr.push(minValue + i * increment);
    }
    return arr;
  }

  function renderRadioButton(isSelected: boolean) {
    return (
      <div className={isSelected ? style.radioButtonSelected : style.radioButton}>
        {isSelected && <div className={style.radioButtonInnerCircle} />}
      </div>
    );
  }

  function renderPromotionTag() {
    return (
      <div className={style.promotionTag}>
        <span>{`BEST VALUE - SAVE $${numberWithCommas(parseFloat(yearlySaving.toFixed(2)))}`}</span>
      </div>
    );
  }

  function renderPaymentType(paymentInterval: "month" | "year") {
    const pricePerSeat = priceByPlan[selectedPlan][paymentInterval];
    //currentInvoicePreview && console.log('renderPaymentType', {paymentInterval, currentInvoicePreview, monthlyInvoicePrview, yearlyInvoicePreview})
    const priceText = `$${numberWithCommas(parseFloat(pricePerSeat.toFixed(2)))}`;
    const text = `${priceText} a month per user`;

    return !invoicePreviewsAtomValue ? (
      <Skeleton key={paymentInterval} width={"93%"} height={47} className={style.paymentTypeInnerContainer} />
    ) : (
      <div
        key={paymentInterval}
        onClick={() => setSelectedPeriod(paymentInterval)}
        className={style.paymentTypeInnerContainer}
      >
        {renderRadioButton(paymentInterval === selectedPeriod)}
        <div className={style.paymentType}>
          <div className={style.flexContainer}>
            <span className={style.paymentTypeText}>{paymentInterval === "year" ? "Yearly" : "Monthly"}</span>
            {paymentInterval === "year" && !!yearlySaving && renderPromotionTag()}
          </div>
          <div className={style.priceContainer}>
            <span className={style.paymentTypePrice}>{text}</span>
          </div>
        </div>
      </div>
    );
  }

  function renderPromotionCodeInput() {
    return (
      <div className={style.promotionContainer}>
        <input className={style.promotionInput} placeholder="Promo Code" />
        <span className={style.applyPromotion}>Apply</span>
      </div>
    );
  }

  function renderDueNextPeriod(planInvoicePreview: InvoicePreview, nextPeriodFormattedDate: string) {
    const price = planInvoicePreview?.dueToday * seatsMultiplyFactor;
    return (
      <React.Fragment>
        {!planInvoicePreview ? (
          <Skeleton width={"93%"} height={24} />
        ) : (
          <div className={style.nextMonthLineItem}>
            <span style={{ fontWeight: "600" }}>{`${nextPeriodFormattedDate}`}</span>
            <div className={style.nextPeriodLineItem}>
              <span>{`$${numberWithCommas(
                parseFloat(selectedPeriod === "year" ? (price / 12).toFixed(2) : price.toFixed(2))
              )} per month`}</span>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  function renderInvoiceDetails(pricePerSeat: number, showFreeTrial?: boolean, customStyle?: CSSProperties) {
    const usersCountText = `${numberWithCommas(seatsToBuy)} ${seatsToBuy > 1 ? "users" : "user"}`;
    const planInvoicePreview = invoiceByPlanCache && invoiceByPlanCache[selectedPlan][selectedPeriod];
    const invoiceBreakDownText = !planInvoicePreview ? "" : `($${pricePerSeat.toFixed(2)} / month x ${usersCountText})`;
    return (
      <div style={customStyle} className={style.invoceDetailsContainer}>
        <div className={style.flexColumnContainer}>
          {renderDueNextPeriod(planInvoicePreview!, "Subtotal")}
          {!invoicePreviewsAtomValue ? (
            <Skeleton width={"93%"} />
          ) : (
            <div className={style.usersLineItem}>{false && <span>{usersCountText}</span>}</div>
          )}
          <span className={style.invoiceBreakDownText}>{invoiceBreakDownText}</span>
        </div>
      </div>
    );
  }

  function renderCheckmarck(color: string) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none">
        <path
          d="M1 5.12356L4.63879 8.65533L11.9164 1.5918"
          stroke={color}
          strokeWidth="1.3552"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  function renderFeature(feature: string) {
    return (
      <div key={feature} className={style.planFeature}>
        {renderCheckmarck("#1973FF")}
        <span className={style.planFeature}>{feature}</span>
      </div>
    );
  }

  async function onPlanClicked(plan: Plan) {
    setSelectedPlan(plan);
  }

  function renderPlan(plan: Plan) {
    const isSelected = plan === selectedPlan;
    const isCurrentPlan = plan === currentPlan && selectedPeriod === currentInterval;
    const planContainerStyle = isCurrentPlan
      ? style.currentPlanContainer
      : isSelected
      ? style.selectedPlanContainer
      : style.planContainer;
    const pricePerUser = priceByPlan[plan][selectedPeriod];
    return !invoicePreviewsAtomValue ? (
      <div className={style.planContainerSkeleton}>
        <Skeleton height={"100%"} />
      </div>
    ) : (
      <div key={plan} onClick={() => (isCurrentPlan ? {} : onPlanClicked(plan))} className={planContainerStyle}>
        <div className={style.planPriceContainer}>
          <span className={isSelected ? style.selectedPlanPrice : style.planPrice}>{`$${pricePerUser.toFixed(
            2
          )}`}</span>
          <div className={style.planPricePerUser}>
            <span>Per user</span>
            <span>Per month</span>
          </div>
        </div>
        <div className={isSelected ? style.selectedPlanFeaturesContainer : style.planFeaturesContainer}>
          <div className={style.planFeaturesList}>
            {featuresByPlan[plan].map((i) => {
              return renderFeature(i.text);
            })}
          </div>
        </div>
        <div className={isCurrentPlan ? style.currentPlan : style.planTypeContainer}>
          {isCurrentPlan ? (
            <span>Current Plan</span>
          ) : (
            <React.Fragment>
              <div className={isSelected ? style.selectedPlanOption : style.planOption}>
                {isSelected && <div className={style.selectedInnerCircle} />}
              </div>
              <span>{planToString(plan)}</span>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  function renderPlanSelection() {
    return (
      <div className={style.plansContainer}>
        {renderPlan(Plan.team)}
        {renderPlan(Plan.pro)}
      </div>
    );
  }

  function renderSeperator(customStyle?: CSSProperties) {
    return <div style={customStyle} className={style.seperator} />;
  }

  function renderPaymentDetailsWithPlanSelectionOnly() {
    return (
      <React.Fragment>
        <div className={style.paymentDetailsContainer}></div>
        {renderPlanSelection()}
        {renderSeperator()}
        {renderInvoiceDetails(priceByPlan[selectedPlan][selectedPeriod])}
        {!invoicePreviewsAtomValue ? (
          <Skeleton width={"93%"} className={style.continueButtonSkeleton} />
        ) : (
          <button onClick={onPayButtonClicked} className={style.continueButton}>
            {loading ? "Loading..." : "Continue"}
          </button>
        )}
      </React.Fragment>
    );
  }

  function renderPaymentIntervalOptions() {
    return (
      <div className={style.paymentTypeContainer}>
        {["year", "month"].map((interval) => {
          return renderPaymentType(interval as "year" | "month");
        })}
      </div>
    );
  }

  function renderDropDownPicker() {
    return (
      <div className={style.dropDownContainer} data-testid={"checkout seats count"}>
        <DropdownPicker<number>
          options={dropDownOptions}
          titles={(seatsCount) => `${seatsCount}`}
          optionRenderer={(seatsCount) => {
            return { element: <span>{`${seatsCount.option} seats`}</span>, enabled: true };
          }}
          onChange={(selectedSeats) => setSeatsToBuy(selectedSeats)}
          isSelected={(v) => v === seatsToBuy}
          enabled={true}
          scrollToSelected={true}
          optionsPickerStyle={{ maxHeight: "296px" }}
          pickerCustomIcon={getPathPrefix("/images/chevron-black.svg")}
        />
      </div>
    );
  }

  function renderSeatsSelection() {
    return (
      <div className={style.seatsSelectionContainer}>
        <div className={style.seatsSelectionContent}>
          <span>How many seats?</span>
          {renderDropDownPicker()}
        </div>
      </div>
    );
  }

  function renderPaymentDetails() {
    return (
      <React.Fragment>
        {renderSeatsSelection()}
        {renderPaymentIntervalOptions()}
        {false && renderPromotionCodeInput()}
        {false && renderSeperator({ marginTop: "32px" })}
        {renderInvoiceDetails(priceByPlan[selectedPlan][selectedPeriod], false)}
      </React.Fragment>
    );
  }

  function renderContinueButton() {
    return (
      <div className={style.ctaButton}>
        {!invoicePreviewsAtomValue ? (
          <Skeleton width={"93%"} className={style.continueButtonSkeleton} />
        ) : (
          <button onClick={onPayButtonClicked} className={style.continueButton}>
            {loading ? "Loading..." : "Continue"}
          </button>
        )}
      </div>
    );
  }

  async function onPayButtonClicked() {
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "trial-modal-continue-clicked", selectedPlan);
    const subscriptionValue = invoiceByPlanCache ? invoiceByPlanCache[selectedPlan][selectedPeriod].dueNextPeriod : 0;
    tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.PAID_PLAN_SELECTED, subscriptionValue);
    const planInvoicePreview = invoiceByPlanCache && invoiceByPlanCache[selectedPlan][selectedPeriod];
    setShowPaymentPage(true);
    setLoading(true);
    if (
      hasPaymentMethod ||
      (planInvoicePreview &&
        !planInvoicePreview.trialEndDate &&
        planInvoicePreview.dueToday < consts.MIN_STRIPE_ALLOWED_AMOUNT)
    ) {
      //if we do not charge the customer or he already has payment method, let stripe's customer portal handle it. We should remove it once we will have a full-blow support for all billing scnearios
      useCustomerPortal({ type: "upgrade", planId: selectedPlan, interval: selectedPeriod });
    } else {
      //const secret = await createPaymentIntent(selectedPlan, seatsCount);
      const secret = await createSetupIntent({
        newPlan: selectedPlan,
        seatsCount: seatsToBuy,
        billingInterval: selectedPeriod,
        nt: true,
      });
      setStripeSecret(secret);
      setLoading(false);
    }
  }

  function renderPayButton() {
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "confirm-payment", selectedPlan);
    return !stripeSecret ? (
      <Skeleton containerClassName={style.checkoutSkeletonButton} count={1} width={460} height={36} />
    ) : (
      <div className={classNames(style.ctaButton, { [style.ctaWithMessage]: message })}>
        <button onClick={() => (confirmPayment ? {} : setcConfirmPayment(true))} className={style.continueButton}>
          {confirmPayment ? "Loading..." : "Complete purchase"}
        </button>
        {
          <span className={classNames(style.paymentMessage, { [style.showPaymentMessage]: message })}>
            {message ?? null}
          </span>
        }
      </div>
    );
  }

  function renderCheckoutSkeleton() {
    return (
      <div className={style.checkoutSkeletonContainer}>
        <Skeleton containerClassName={style.checkoutSkeleton} count={4} width={460} height={44} />
      </div>
    );
  }

  function renderStripeCheckout() {
    return !stripeSecret ? renderCheckoutSkeleton() : <React.Fragment>{renderPaymentForm()}</React.Fragment>;
  }

  function renderCheckout(checkoutType: "small-modal" | "modal-with-plans") {
    return (
      <div className={style.checkoutContainer}>
        {!showPaymentPage &&
          (checkoutType === "small-modal" ? renderPaymentDetails() : renderPaymentDetailsWithPlanSelectionOnly())}
        {showPaymentPage && renderStripeCheckout()}
      </div>
    );
  }

  function renderPaymentForm() {
    return (
      <Elements options={{ clientSecret: stripeSecret!, appearance: { theme: "stripe" } }} stripe={stripePromise}>
        <CheckoutForm
          confirmPayment={confirmPayment}
          onLoadingChanged={(isLoading) => {
            if (!isLoading) {
              setcConfirmPayment(false);
            }
          }}
          user={user}
          onError={(error) => {
            console.log("error", error);
          }}
          amount={invoiceByPlanCache ? invoiceByPlanCache[selectedPlan][selectedPeriod].dueNextPeriod : 0} //track the subscription's non-prorated value
          message={message}
          setMessage={setMessage}
        />
      </Elements>
    );
  }

  function renderModalWithPlanSelection() {
    return (
      <div ref={ref} className={style.smallContainer}>
        <div className={style.leftSection} style={{ borderRadius: "8px" }}>
          <div className={showPaymentForm ? style.headerContainer : style.headerContainerCloseOnly}>
            {showPaymentForm && (
              <BackArrow
                onClick={() => {
                  setShowPaymentPage(false);
                  setMessage(null);
                }}
              />
            )}
            <CloseIcon style={{ cursor: "pointer" }} onClick={onDismiss} />
          </div>
          {!invoicePreviewsAtomValue ? (
            <div className={style.checkoutHeaderSkeleton}>
              <Skeleton />
            </div>
          ) : (
            <span
              style={{ textAlign: "center" }}
              className={showPaymentForm ? style.paymentFormHeader : style.checkoutHeader}
            >
              {showPaymentForm ? `Purchase WorkCanvas.com ${planToString(selectedPlan)}` : "What's the plan, boss?"}
            </span>
          )}
          {showPaymentForm ? null : !invoicePreviewsAtomValue ? (
            <div className={style.periodPickerSkeletion}>
              <Skeleton height={"100%"} />
            </div>
          ) : (
            <BillingPeriodPicker
              defaultPeriod={selectedPeriod}
              onOptionChanged={(option) => setSelectedPeriod(option)}
              yearlySaving={yearlySaving}
            />
          )}
          {renderCheckout("modal-with-plans")}
        </div>
      </div>
    );
  }

  function renderSmallModalRightSection(viewType: "timeline" | "image") {
    const rightSectionText =
      source?.name === "templates"
        ? `Unlock ${source.value} and 100+ premium templates`
        : "Unlock Pro with 100+ premium templates";
    return viewType === "timeline" ? (
      <div className={style.rightSectionContent}>
        <div className={style.timeLineWrapper}>
          <img src={getPathPrefix("/images/testimonial.svg")} />
        </div>
      </div>
    ) : (
      <React.Fragment>
        <span className={style.rightSectionTitle}>{rightSectionText}</span>
        <img className={style.image} src={getPathPrefix("/images/checkout/checkout-templates.svg")} />
      </React.Fragment>
    );
  }

  function renderSmallModal() {
    const isDowngraded = user?.planInfo?.is_downgraded;
    return (
      <div ref={ref} className={style.container}>
        {!showPaymentForm && (
          <div data-testid="close-payment-modal">
            <CloseIcon
              style={{ cursor: "pointer", position: "absolute", top: "16px", right: "16px", zIndex: "100" }}
              onClick={onDismiss}
            />
          </div>
        )}
        <div className={style.rightSection}>{renderSmallModalRightSection("image")}</div>
        <ErrorBoundary
          fallback={
            <div className={style.leftSection}>
              <h1>Oops, something went wrong...</h1>
            </div>
          }
          onError={(error, info) => {
            console.error("Payment error", error, info);
            tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "payment-modal-error", error.message, source?.name);
            Sentry.addBreadcrumb({
              message: "Error rendering checkout form (modal)",
              category: "error",
              level: "error",
            });
            Sentry.captureException(error, { tags: { type: "billing", page: "payment-modal" } });
          }}
        >
          <div className={style.leftSection}>
            {showPaymentForm && (
              <BackArrow
                onClick={() => {
                  setShowPaymentPage(false);
                  setMessage(null);
                }}
                customStyle={{ cursor: "pointer", position: "absolute", top: "12px", left: "16px", zIndex: "100" }}
              />
            )}
            <div className={style.checkoutWrapper}>
              <span className={style.checkoutHeader}>
                {!isDowngraded ? "Try WorkCanvas Pro Today" : "Upgrade To WorkCanvas Pro"}
              </span>
              {renderCheckout("small-modal")}
            </div>
            {showPaymentPage ? renderPayButton() : renderContinueButton()}
          </div>
        </ErrorBoundary>
      </div>
    );
  }

  return true ? renderSmallModal() : renderModalWithPlanSelection();
}
