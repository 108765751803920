import React, { forwardRef, useRef } from "react";
import IconBase, { IconProps } from "./icon-base";
import { useHover } from "frontend/ui-components/floaters/use-hover";
import { Tooltip } from "frontend/ui-components/floaters/tooltip";

type HiddenCursorsProps = IconProps & { tooltip?: boolean };

let HiddenCursors = forwardRef<SVGSVGElement, HiddenCursorsProps>((props, ref) => {
  const hoverRef = useRef<any>(null);
  const hover = useHover(hoverRef);
  const { tooltip = false, ...iconProps } = props;

  return (
    <div data-testid="hiddenCursorIcon" ref={hoverRef} style={{ display: "flex", justifyContent: "center" }}>
      <IconBase
        ref={ref}
        fill="none"
        renderPath={(color: string) => {
          return (
            <>
              <path
                d="M1.91406 1.91376L20.0003 7.9425L11.962 11.9617L7.94281 20L1.91406 1.91376Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <line x1="14.3963" y1="1.4449" x2="1.60369" y2="18.8063" stroke="currentColor" strokeWidth="1.5" />
            </>
          );
        }}
        viewBox="0 0 21 21"
        {...iconProps}
      >
        {props.children}
      </IconBase>
      {hover && tooltip && <Tooltip label={"Hidden cursors"} relativeTo={hoverRef}></Tooltip>}
    </div>
  );
});

HiddenCursors.displayName = "HiddenCursors";

export { HiddenCursors };
