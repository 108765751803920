export function openURL(url: string, newTab: boolean = false) {
  if (typeof window === "undefined") {
    return;
  }

  if (window === window.parent) {
    window.open(url, newTab ? "_blank" : "_self");
  } else {
    window.parent.postMessage({ name: "redirect", url, newTab }, "*");
  }
}
