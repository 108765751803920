import { useEffect, useRef } from "react";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import { TemplatesSection } from "frontend/modals/templates-modal";
import { useDebounce } from "./debounce";

const DEBOUNCE_TIMEOUT = 1000;
const SEACH_TERM_MIN_LENGTH = 2;

function getTemplatesCount(sections: TemplatesSection[]) {
  return sections.reduce((accumulator, section) => accumulator + section.templates.length, 0);
}

export default function useTrackSearchTerm(searchTerm: string, sections: TemplatesSection[]) {
  const debouncedSearchTerm = useDebounce(searchTerm, DEBOUNCE_TIMEOUT);
  const templatesCountRef = useRef(getTemplatesCount(sections));

  useEffect(() => {
    templatesCountRef.current = getTemplatesCount(sections);
  }, [sections]);

  useEffect(() => {
    if (debouncedSearchTerm.length > SEACH_TERM_MIN_LENGTH) {
      tracking.trackAnalyticsEvent("search-template", {
        category: consts.TRACKING_CATEGORY.TEMPLATES,
        searchTerm: debouncedSearchTerm,
        searchTermLength: debouncedSearchTerm.length,
        templatesResultCount: templatesCountRef.current,
      });
    }
  }, [debouncedSearchTerm]);
}
