import { useCustomerPortal } from "frontend/billingUtils";
import tracking from "frontend/tracking";
import { copyTextToClipboard } from "frontend/utils/clipboard-utils";
import { useEffect, useRef, useState } from "react";
import consts, { Plan,  } from "shared/consts";
import { BillingInterval } from "shared/datamodel/schemas/billing";
import { PromotionType } from "shared/datamodel/schemas/promotion";
import style from "./instructions.module.css";
import { getPathPrefix } from '../../utils/getPathPrefix';

export default function Instructions({
  id,
  code,
  validUntill,
  type,
  onClose,
  plan,
  interval,
  hideValidUntill,
  title
}: {
  id: string;
  code: string;
  validUntill: Date;
  type: PromotionType;
  onClose: () => void;
  plan:Plan;
  interval: BillingInterval,
  hideValidUntill?:boolean;
  title?:string
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [linkCopied, setLinkCopied] = useState(false);
  const [userCopiedAtleastOnce, setUserCopiedAtleastOnce] = useState(false);
  const [shakeToolTip, setShakeToolTip] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.PROMOTIONS, "promotion_instructions_seen", id, type);
  }, []);

  useEffect(() => {
    const tooltip = document.getElementById("checkout-button");
    if (tooltip && shakeToolTip) {
      tooltip.className = style["ctaShaked"];
      //reset the style after 1s to be able to play the animation again on style change
      setTimeout(() => {
        const tooltip = document.getElementById("checkout-button");
        if (tooltip) {
          tooltip.className = style["cta"];
        }
        setShakeToolTip(false);
      }, 4000);
      return;
    }
  }, [shakeToolTip]);

  const steps = [
    {
      text: "Enter Promotion Code",
      image: getPathPrefix("/images/promotions/promotionStep1.jpg"),
    },
    {
      text: "Click “Apply”",
      image: getPathPrefix("/images/promotions/promotionStep2.jpg"),
    },
    {
      text: "Click “Confirm”",
      image: getPathPrefix("/images/promotions/promotionStep3.jpg"),
    },
  ];

  function handleCopyClick() {
    tracking.trackEvent(consts.TRACKING_CATEGORY.PROMOTIONS, "promotion_instructions_copy_clicked", id, type);
    copyTextToClipboard(code, inputRef.current!);
    setLinkCopied(true);
    if (!userCopiedAtleastOnce) {
      setUserCopiedAtleastOnce(true);
    }
    setTimeout(() => {
      setLinkCopied(false);
    }, 2000);
  }

  function handleOnClose() {
    tracking.trackEvent(consts.TRACKING_CATEGORY.PROMOTIONS, "promotion_instructions_closed", id, type);
    onClose && onClose();
  }

  function renderCloseIcon() {
    return (
      <svg
        className={style.closeIcon}
        onClick={handleOnClose}
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="10"
        viewBox="0 0 9 10"
        fill="none"
      >
        <path d="M0.59375 0.73877L7.87239 8.54665" stroke="#848199" strokeLinecap="round" />
        <path d="M7.87305 0.738281L0.594409 8.54616" stroke="#848199" strokeLinecap="round" />
      </svg>
    );
  }

  function getCouponText() {
    if(hideValidUntill) {
      return `Your promotion code: `;
    } else {
      return `Promotion code valid untill: ${validUntill.toDateString()}`;
    }
  }

  function renderCoupon() {
    return (
      <div className={style.couponContainer}>
        <span className={style.validUntill}>{getCouponText()}</span>
        <div className={style.copyCouponFlex}>
          {linkCopied ? (
            <input ref={inputRef} className={style.couponCopied} value={"Code copied!"} />
          ) : (
            <input ref={inputRef} className={style.couponCode} value={code} />
          )}
          <button className={style.copyCoupon} onClick={handleCopyClick}>
            Copy code
          </button>
        </div>
      </div>
    );
  }

  function renderStep(step: any, index: number) {
    return (
      <div className={style.stepContainer}>
        <div className={style.stepTitle}>
          <span className={style.stepNumber}>{++index}</span>
          <span className={style.stepText}>{step.text}</span>
        </div>
        <img className={style.stepImage} src={step.image} />
      </div>
    );
  }

  async function onCtaClick() {
    if (!userCopiedAtleastOnce) {
      tracking.trackEvent(consts.TRACKING_CATEGORY.PROMOTIONS, "promotion_instructions_cta_clicked_no_copy", id, type);
      setShakeToolTip(true);
      return;
    }
    setLoading(true)
    tracking.trackEvent(consts.TRACKING_CATEGORY.PROMOTIONS, "promotion_instructions_cta_clicked", id, type);
    await useCustomerPortal({ openInNewTab: true, type: "upgrade", planId: plan, interval });
    setLoading(false);
  }

  return (
    <div className={style.container}>
      {renderCloseIcon()}
      <div className={style.content}>
        <div className={style.header}>
          <span className={style.headerTitle}>{title || 'How To Apply Your Discount?'}</span>
          <span className={style.headerDescription}>
            Copy your promotion code and take these steps in the checkout page
          </span>
        </div>
        <div className={style.stepsContainer}>{steps.map((i, index) => renderStep(i, index))}</div>
        {renderCoupon()}
        <div className={style.continueToCheckout}>
          <span className={shakeToolTip ? style.copyCouponError : style.copyCouponErrorHidden}>
            Don't forget to copy the promotion code! (-:{" "}
          </span>
          <button id={"checkout-button"} className={style.cta} onClick={onCtaClick}>
            {loading ? 'Loading...' : 'Continue To Checkout'}
          </button>
        </div>
      </div>
    </div>
  );
}
