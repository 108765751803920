import style from "./create-template-modal.module.css";
import tracking from "frontend/tracking";
import useOutsideRef from "frontend/utils/click-outside-handler";
import React, { useEffect, useRef, useState } from "react";
import consts from "shared/consts";
import FormInputField from "frontend/ui-components/form-fields/form-input-field";
import FormTextareaField from "frontend/ui-components/form-fields/form-textarea-field";
import { FormTag } from "frontend/ui-components/form-fields/form-tags-field";
import { editCanvasTemplate } from "frontend/api";
import StyledButton, { ButtonStyle } from "frontend/ui-components/styled-button";
import {
  CanvasTemplateMinimal,
  canvasCreateTemplateSchema,
  canvasTemplateMinimalSchema,
  canvasUpdateTemplateMinimalSchema,
} from "shared/datamodel/schemas/canvas-template";
import { z } from "zod";
import { useTeam } from "frontend/hooks/use-team";
import useCanvasTemplates from "frontend/hooks/use-canvas-templates";

export default function EditTemplateModal({
  onDismiss,
  template,
}: {
  onDismiss: () => void;
  template: CanvasTemplateMinimal;
}) {
  const { tags, editTemplate } = useCanvasTemplates();

  const initFromValues = {
    name: template.name,
    description: template.description,
    tags: (tags
      ?.filter((tag) => tag.templates.includes(template.id))
      .map((tag) => {
        if (tag.id && tag.name) {
          return { id: tag.id.toString(), name: tag.name };
        }
      }) ?? []) as FormTag[],
    permission: template.permission,
    thumbnailImageData: template.thumbnailImageURL,
  };

  const [formValues, setFormValues] = useState<Record<string, any>>(initFromValues);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isEditing, setEditing] = useState(false);

  const { getDefaultAccountTeam } = useTeam();
  const accountName = getDefaultAccountTeam()?.name;

  const modalRef = useRef(null);

  //We need to disable this detection while a user sees the upgrade modal,
  //otherwise it closes the window when a user tries to click on upgrade plan while upgrading to use a paid template
  useOutsideRef(modalRef, onDismiss, { preventScroll: false });

  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.TEMPLATES, "edit-template-shown", template.id, template.name);
  }, []);

  const errorMessages: Record<string, string> = {
    name: "Name should not be empty",
    description: "Description should not be empty",
  };

  function setProperty(name: string, value: any) {
    setFormValues((values) => ({ ...values, [name]: value }));
  }

  async function submitClicked() {
    try {
      setEditing(true);
      const updateTemplate = canvasUpdateTemplateMinimalSchema.parse({ id: template.id, ...formValues });
      await editCanvasTemplate(template.id, updateTemplate);
      editTemplate(canvasTemplateMinimalSchema.parse({ ...template, ...formValues }));
      tracking.trackEvent(
        consts.TRACKING_CATEGORY.TEMPLATES, // category
        "saved_edited_template_to_lib", // action
        formValues.permission,
        template.id
      );
      onDismiss();
    } catch (error) {
      console.error(error);
      const failedFields: Record<string, string> = {};
      if (error instanceof z.ZodError) {
        const failedPaths = error.issues.map((i) => i.path[0]);
        for (const path of failedPaths) {
          failedFields[path] = errorMessages[path];
        }
        setErrors(failedFields);
      }
    } finally {
      setEditing(false);
    }
  }

  function onBlurField(id: string) {
    validateField(id);
  }

  function validateField(id: string) {
    setErrors((e) => {
      let { [id]: remove, ...rest } = e;
      try {
        canvasCreateTemplateSchema.parse(formValues);
      } catch (error) {
        if (error instanceof z.ZodError) {
          const failedPaths = error.issues.map((i) => i.path[0]);
          if (failedPaths.includes(id)) {
            rest[id] = errorMessages[id];
          }
        }
      }
      return rest;
    });
  }

  function renderCloseIcon() {
    return (
      <svg
        className={style.closeIcon}
        onClick={(e: any) => {
          e.stopPropagation();
          onDismiss();
        }}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.36407 6.36396L19.092 19.0919M19.092 6.36396L6.36407 19.0919"
          stroke="#848199"
          strokeWidth="1.74"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  function renderPermissionOptions() {
    return (
      <div className={style.permissionContainer}>
        <p>Select who can use this template</p>
        <label>
          <input
            type="radio"
            name="permission"
            value="private"
            checked={formValues.permission === "private"}
            onChange={() => setProperty("permission", "private")}
          />
          Private <span>(Only you)</span>
        </label>
        <label>
          <input
            type="radio"
            name="permission"
            value="account"
            checked={formValues.permission === "account"}
            onChange={() => setProperty("permission", "account")}
          />
          Account <span>{`(Everyone in ${accountName})`}</span>
        </label>
      </div>
    );
  }

  return (
    <div className={style.container} ref={modalRef}>
      {renderCloseIcon()}
      <div className={style.fieldsContainer}>
        <div className={style.title}>Edit template</div>
        <FormInputField
          id="name"
          title={"Template name"}
          placeholder="Write here a template name"
          value={formValues.name}
          error={errors.name}
          onBlur={onBlurField}
          onChange={(value) => setProperty("name", value)}
          customInputStyle={{ height: 39, fontSize: 12 }}
        />
        <FormTextareaField
          id="description"
          title={"Description"}
          placeholder="Write a description to let others know how and when they  can use it"
          value={formValues.description}
          error={errors.description}
          onBlur={onBlurField}
          height={57}
          onChange={(value) => setProperty("description", value)}
          customStyle={{ fontSize: 12 }}
        />
        {renderPermissionOptions()}
        <div className={style.buttonContainer}>
          <StyledButton
            title="Cancel"
            onClick={() => {
              tracking.trackEvent(
                consts.TRACKING_CATEGORY.TEMPLATES, // category
                "cancel_edit_template", // action
                formValues.permission,
                template.id
              );
              onDismiss();
            }}
            style={ButtonStyle.outline}
            customStyle={{
              width: 174,
              fontSize: 16,
              fontWeight: 500,
              height: 41,
            }}
          />
          <StyledButton
            title="Save template"
            onClick={submitClicked}
            loading={isEditing}
            loadingTitle="Saving template"
            customStyle={{
              width: 174,
              fontSize: 16,
              fontWeight: 500,
              height: 41,
            }}
          />
        </div>
      </div>
      <div className={style.previewContainer}>
        <img src={formValues.thumbnailImageData} />
      </div>
    </div>
  );
}
