import React, { useState } from "react";
import StyledButton, { ButtonStyle } from "frontend/ui-components/styled-button";
import style from "./template-preview.module.css";
import { CanvasTemplateMinimal } from "../../shared/datamodel/schemas/canvas-template";
import AppLoader from "frontend/loader/app-loader";
import { getPathPrefix } from "../utils/getPathPrefix";
import tracking from "frontend/tracking";
import consts from "shared/consts";

export default function TemplatePreviewContent({
  template,
  onChooseTemplate,
  upgradeRequired,
  upgrade,
  previewLoaded,
  upgradeText,
  isIntegrationTemplate,
  renderTemplatePreview,
}: {
  template: CanvasTemplateMinimal;
  onChooseTemplate: (template: CanvasTemplateMinimal) => void;
  upgradeRequired: boolean;
  upgrade: () => void;
  previewLoaded: boolean;
  upgradeText: string;
  isIntegrationTemplate: boolean;
  renderTemplatePreview: (renderType: "image" | "canvas") => JSX.Element | undefined;
}) {
  const [showCopySuccess, setShowCopySuccess] = useState(false);

  const title =
    template.name.charAt(0).toLocaleUpperCase() +
    template.name.slice(1) +
    (!template.name.toLowerCase().endsWith("template") ? " Template" : "");

  const description = template.description;

  const renderType = isIntegrationTemplate ? "image" : "canvas";

  function handleCopyLink() {
    tracking.trackEvent(consts.TRACKING_CATEGORY.TEMPLATES, "preview_link_copied");
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => setShowCopySuccess(true))
      .catch(() => setShowCopySuccess(false));
    setShowCopySuccess(true);
    setTimeout(() => setShowCopySuccess(false), 2000);
  }

  return (
    <>
      <div className={style.copyLinkButton}>
        <StyledButton
          title={showCopySuccess ? "Link Copied!" : "Copy link to share"}
          onClick={handleCopyLink}
          icon={getPathPrefix("/images/template-previews/link-icon.svg")}
          style={ButtonStyle.outline}
          customStyle={{ minWidth: "179px" }}
        />
      </div>
      <div className={style.container}>
        <div className={style.content}>
          <span className={style.title}>{title}</span>
          <span className={style.description}>{description}</span>
          <div data-testid="usetemplatebutton">
            <StyledButton
              title={upgradeRequired ? upgradeText : "Use Template"}
              onClick={() => (upgradeRequired ? upgrade() : onChooseTemplate(template))}
              icon={upgradeRequired ? getPathPrefix("/images/crown-gold.svg") : null}
              customStyle={{ position: "relative", top: "12px" }}
              style={upgradeRequired ? ButtonStyle.outline : ButtonStyle.filled}
            />
          </div>
        </div>
        {template && (
          <div className={style.previewContainer}>
            {!previewLoaded && <AppLoader customStyle={{ position: "absolute", inset: 0, transform: "unset" }} />}
            {renderTemplatePreview(renderType)}
          </div>
        )}
      </div>
    </>
  );
}
