import { PortalID, usePortal } from "frontend/modal/usePortal";
import useStateValue from "frontend/state/value";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import style from "./app-header.module.css";
import "@mondaydotcomorg/monday-ui-components/dist/ProductsSwitcher.css";
import "@mondaydotcomorg/monday-ui-components/dist/main.css";
import "monday-ui-react-core/dist/main.css";
import dynamic from "next/dynamic";
import PaymentNotification from "./payment-notification";
import BillingNotification from "./billing-notification";
import TrialDaysLeftNotification from "./trial-days-left-notification";
import UpgradeFromFreeNotification from "./upgrade-from-free-notification";
import { isTrialActive, isTrialExpired } from "shared/util/billing";
import AppLogo from "../ui-components/logo";
import AppHeaderBoard from "./app-header-board";
import Profile from "frontend/user-presence/profile";
import { Board } from "shared/datamodel/schemas";
import InviteNotification from "./invite-notification";
import DowngradeNotification from "./downgrade-notification";
import { useAtomValue } from "jotai";
import { accountSubscribedCountAtom, userTipsAtom } from "state-atoms";
import { TipName } from "shared/datamodel/schemas";
import { markTipSeen } from "frontend/api";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import { useSetAtom } from "jotai";
import { isBannerNotificationOnAtom } from "state-atoms/general-atoms";
import { useBoardValue } from "frontend/state/board-state";
import { initButtonText, useRestricted } from "frontend/hooks/use-restricted";
import { Tooltip } from "frontend/ui-components/floaters/tooltip";
import StyledButton, { ButtonStyle } from "frontend/ui-components/styled-button";
import { InstanceType } from "shared/datamodel/schemas/ability";
import AskToEditToast from "frontend/ui-components/toast/ask-to-edit-toast";
import { useHover } from "frontend/ui-components/floaters/use-hover";
import useAbility from "frontend/hooks/use-ability";
import { CheckoutSource } from "frontend/billing/checkout";
import { getPathPrefix } from "../utils/getPathPrefix";

const ProductsSwitcher = dynamic(() => import("@mondaydotcomorg/monday-ui-components/dist/ProductsSwitcher"), {
  ssr: false,
});

export default function AppHeader({
  isBoardHeader = true,
  board = null,
  showDowngradedNotification = false,
  showsSwitcher = true,
  title = null,
  renderUpgradeCTA,
  renderBoardUsersPresence,
  canEditBoard,
  children,
  onInviteClick,
  onCreateTemplate,
  onCreateTemplateLink,
  setShowUpgradeModal,
  onRestrictedClick,
}: {
  isBoardHeader: boolean;
  board?: Board | null;
  showDowngradedNotification?: boolean;
  showsSwitcher?: boolean;
  title?: JSX.Element | null;
  renderUpgradeCTA?: () => JSX.Element;
  renderBoardUsersPresence?: any;
  canEditBoard?: boolean | null;
  children?: any;
  onInviteClick?: (title?: string) => void;
  onCreateTemplate?: () => void;
  onCreateTemplateLink?: () => void;
  setShowUpgradeModal?: (reason: CheckoutSource | null) => void;
  onRestrictedClick?: () => void;
}) {
  const userTips = useAtomValue(userTipsAtom);

  const [{ user, account, boards, teams }] = useStateValue();
  const boardValue = useBoardValue();

  const [showNotification, setShowNotification] = useState(false);
  const setIsBannerNotificationOn = useSetAtom(isBannerNotificationOnAtom);
  const [showPaymentNotification, setShowPaymentNotification] = useState(false);
  const [showBillingNotification, setShowBillingNotification] = useState(false);
  const [showTrialNotification, setShowTrialNotification] = useState(false);
  const [showUpgradeFromFreeNotification, setShowUpgradeFromFreeNotification] = useState(false);
  const [showInviteNotification, setShowInviteNotification] = useState<boolean>(
    !userTips.has(TipName.inviteNotification)
  );
  const target = usePortal(PortalID.AppHeader);
  const router = useRouter();
  const boardUserValue = boardValue?.length ? boardValue[0].user : null;
  const { canPerformAnyAction } = useAbility();
  const { showAskToEditToast, viewerButtonText } = useRestricted();
  const shouldShoWRestrictedButton = user && !canPerformAnyAction(user, teams[0], InstanceType.Team);

  const viewerRef = useRef<HTMLDivElement>(null);
  const viewerHover = useHover(viewerRef);
  const accountSubscribedCount = useAtomValue(accountSubscribedCountAtom);

  useEffect(() => {
    const isHomePage = router.pathname === "/";
    const showShowPaymentNotification =
      accountSubscribedCount > user?.planInfo?.seats_count! &&
      !user?.planInfo?.is_free &&
      user?.planInfo?.trial_end_date === null &&
      user?.account?.id !== "5" &&
      isHomePage; //don't show this to monday account
    const showBillingNotification =
      !user?.planInfo?.invoice_paid &&
      !user?.planInfo?.is_free &&
      !user?.planInfo?.trial_end_date &&
      user?.account?.id !== "5" &&
      isHomePage;
    setShowBillingNotification(showBillingNotification);
    setShowPaymentNotification(showShowPaymentNotification);
    let showTrial;
    if (user) {
      const trialActive = isTrialActive(user.planInfo!);
      const trialExpired = isTrialExpired(user.planInfo!);
      showTrial = trialActive || trialExpired;
      setShowTrialNotification(showTrial);
      setShowUpgradeFromFreeNotification(user?.planInfo?.is_free! && !showTrial);
    }
  }, [user, accountSubscribedCount]);

  useEffect(() => {
    if (
      !boardUserValue?.isAnonymous &&
      (showBillingNotification ||
        showPaymentNotification ||
        showTrialNotification ||
        showDowngradedNotification ||
        showInviteNotification ||
        showUpgradeFromFreeNotification)
    ) {
      setShowNotification(true);
      setIsBannerNotificationOn(true);
    } else {
      setShowNotification(false);
      setIsBannerNotificationOn(false);
    }
  }, [
    showBillingNotification,
    showPaymentNotification,
    showTrialNotification,
    showDowngradedNotification,
    showInviteNotification,
    showUpgradeFromFreeNotification,
  ]);

  function logoClicked() {
    router.push(`/`);
  }

  function renderSwitcher() {
    return (
      account && (
        <ProductsSwitcher
          iconSize={24}
          domain="monday.com"
          protocol="https"
          selectedProductKind="whiteboard"
          moveBy={{ secondary: 10, main: 2 }}
          subdomain={account.slug}
        />
      )
    );
  }

  function renderNotificationContent() {
    if (showBillingNotification) {
      return <BillingNotification accountId={user?.account?.id!} />;
    } else if (showPaymentNotification) {
      return <PaymentNotification unpaidSeats={accountSubscribedCount - user?.planInfo?.seats_count!} />;
    } else if (user && showTrialNotification) {
      return <TrialDaysLeftNotification user={user} renderedIn={showsSwitcher ? "outside-canvas" : "inside-canvas"} />;
    } else if (user && showDowngradedNotification) {
      return <DowngradeNotification user={user} />;
    } else if (user && showUpgradeFromFreeNotification) {
      return (
        <UpgradeFromFreeNotification
          user={user}
          boards={boards}
          renderedIn={showsSwitcher ? "outside-canvas" : "inside-canvas"}
        />
      );
    } else if (showInviteNotification) {
      return (
        <InviteNotification
          onDismiss={() => {
            setShowInviteNotification(false);
            markTipSeen(TipName.inviteNotification);
            tracking.trackEvent(
              consts.TRACKING_CATEGORY.ADMIN_ACTION,
              "invite_notification_dismissed",
              "invite_type",
              isBoardHeader ? "canvas" : "account"
            );
          }}
          onInviteClick={onInviteClick}
        />
      );
    }
  }

  const renderRestrictedActions = () => {
    const tooltip = (
      <div style={{ textAlign: "left", fontSize: 12, fontWeight: 300 }}>
        <p>If you want to edit, click to request access</p>
      </div>
    );
    if (shouldShoWRestrictedButton) {
      return (
        <div className={style.restrictedButtons}>
          <StyledButton
            title={viewerButtonText}
            style={ButtonStyle.outline}
            icon={viewerButtonText === initButtonText ? getPathPrefix("/images/blue-eye.svg") : undefined}
            customStyle={{
              height: 32,
              width: 212,
              fontSize: 13,
              fontWeight: 500,
              padding: viewerButtonText === "Viewer" ? "8px 12px 8px 8px" : "unset",
            }}
            ref={viewerRef}
            iconStyle={{ width: 20, height: 20 }}
            onClick={() => onRestrictedClick && onRestrictedClick()}
          />
          {viewerHover && (
            <Tooltip label={tooltip} relativeTo={viewerRef} side={"bottom"} customStyle={{ borderRadius: 3 }} />
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  function renderContent() {
    return (
      <div className={style.container} id="app-header-container">
        <div className={style.header}>
          <div data-testid="topleftlogo">
            <AppLogo logoClicked={logoClicked} />
          </div>
          {renderRestrictedActions()}
          {isBoardHeader && onCreateTemplate && onCreateTemplateLink && setShowUpgradeModal ? (
            <AppHeaderBoard
              title={title}
              renderUpgradeCTA={renderUpgradeCTA}
              renderBoardUsersPresence={renderBoardUsersPresence}
              onInviteClick={onInviteClick}
              onCreateTemplate={onCreateTemplate}
              onCreateTemplateLink={onCreateTemplateLink}
              setShowUpgradeModal={setShowUpgradeModal}
              canEditBoard={canEditBoard}
            >
              {children}
            </AppHeaderBoard>
          ) : (
            <div className={style.right}>
              <div className={style.content}>{children}</div>
              <Profile isBoardHeader={isBoardHeader} />
              {showsSwitcher && renderSwitcher()}
            </div>
          )}
        </div>
        {showNotification && <div className={style.notification}>{renderNotificationContent()}</div>}
        {showAskToEditToast && <AskToEditToast />}
      </div>
    );
  }

  return ReactDOM.createPortal(renderContent(), target);
}
