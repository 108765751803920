import { createElementId, getUnixTimestampUTC } from "shared/util/utils";
import { Point } from "./schemas";
import { LiveTimelineElement, StaticTimelineElement, liveTimelineSchema, timelineSchema } from "./schemas/timeline";

export function placeStaticTimeline({ x, y }: Point, startDate: number, endDate: number) {
  return {
    id: createElementId(),
    item: timelineSchema.parse({
      type: "timeline",
      x,
      y,
      startDate,
      endDate,
      zIndexLastChangeTime: getUnixTimestampUTC(),
    }) as StaticTimelineElement,
  };
}

export function placeLiveTimeline(
  { x, y }: Point,
  startDate: number,
  endDate: number,
  integrationId: string,
  dateColumnId: string
) {
  return {
    id: createElementId(),
    item: liveTimelineSchema.parse({
      type: "timeline",
      x,
      y,
      startDate,
      endDate,
      integrationId,
      dateColumnId,
      zIndexLastChangeTime: getUnixTimestampUTC(),
    }) as LiveTimelineElement,
  };
}
