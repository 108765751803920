import StyledButton, { ButtonStyle } from "frontend/ui-components/styled-button";
import WalkthroughTipPresenter from "frontend/walkthrough-tips/walkthrough-tip-presenter";
import { CSSProperties, useRef } from "react";
import { TipName } from "shared/datamodel/schemas";

export default function InviteToBoardButton({
  title = "Share",
  style,
  customStyle,
  onInviteClick,
}: {
  title?: string;
  style?: ButtonStyle;
  customStyle?: CSSProperties;
  onInviteClick?: (title?: string) => void;
}) {
  const ref = useRef(null);

  return (
    <>
      <StyledButton
        title={title}
        onClick={() => onInviteClick && onInviteClick(title)}
        style={style}
        customStyle={customStyle}
        ref={ref}
      />
      <WalkthroughTipPresenter tipName={TipName.walkthroughInviteMembers} attachToRef={ref} side="bottom" />
    </>
  );
}
