import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "@firebase/auth";

// TODO: Move to env vars
const firebaseConfig = {
  apiKey: "AIzaSyB7f1AsWK5wrx5255_xCvmFKT77RfW90XY",
  authDomain: "filedriver-291811.firebaseapp.com",
  databaseURL: "https://filedriver-291811-default-rtdb.firebaseio.com",
  projectId: "filedriver-291811",
  storageBucket: "filedriver-291811.appspot.com",
  messagingSenderId: "396019251244",
  appId: "1:396019251244:web:cbc703e95f71737e6ff989",
  measurementId: "G-1ZWBL93H69",
};

const app = initializeApp(firebaseConfig);

export async function signIn() {
  const auth = getAuth(app);
  const provider = new GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/directory.readonly");
  const result = await signInWithPopup(auth, provider);
  const credentials = GoogleAuthProvider.credentialFromResult(result);
  return { email: result.user.email, token: credentials?.accessToken };
}

type GoogleContact = {
  name: string | null;
  email: string;
  photo: string | null;
};

async function handleDirectoryResponse(response: Response) {
  if (response.ok) {
    const json = (await response.json()) as any;
    const people = json.people as Array<any>;
    return people.reduce((array, p) => {
      let name, photo;
      if (p.photos) {
        photo = p.photos[0].url;
      }
      if (p.names) {
        name = p.names[0].value;
      }
      if (p.emailAddresses) {
        const email = p.emailAddresses[0].value;
        const contact = {
          email,
          photo,
          name,
        };
        return [...array, contact];
      }
      return array;
    }, new Array<GoogleContact>());
  }
  return [];
}

export async function getDirectoryContacts(token: string, query: string): Promise<GoogleContact[]> {
  const path = query ? "people:searchDirectoryPeople" : "people:listDirectoryPeople";

  const params: any = {
    sources: "DIRECTORY_SOURCE_TYPE_DOMAIN_PROFILE",
    readMask: "names,emailAddresses,photos",
    pageSize: "100",
  };
  if (query.length > 0) {
    params.query = query;
  }
  const urlParams = new URLSearchParams(params);
  const response = await fetch(`https://people.googleapis.com/v1/${path}?` + urlParams, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return handleDirectoryResponse(response);
}


