import Checkout from "frontend/billing/checkout";
import tracking from "frontend/tracking";
import React, { useEffect, useState } from "react";
import consts from "shared/consts";
import { User } from "shared/datamodel/schemas/user";
import style from "./upgrade-from-free-notification.module.css";
import { getPathPrefix } from '../utils/getPathPrefix';

export default function DowngradeNotification({ user }: { user: User }) {
  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "downgrade-notification-shown");
  }, []);

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  function upgradeClicked() {
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "upgrade-after-downgrade-notification-clicked");
    tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.UPGRADE_CTA);
    setShowUpgradeModal(true);
  }

  return (
    <React.Fragment>
      {showUpgradeModal && (
        <Checkout
          user={user}
          onDismiss={() => {
            setShowUpgradeModal(false);
          }}
        />
      )}
      <div className={style.toolBarWarning}>
        <img src={getPathPrefix("/images/alert.svg")} />
        <span className={style.description}>
          You are currently downgraded to the Free plan. to edit previous canvases and create new ones
        </span>
        <span onClick={upgradeClicked} className={style.link}>
          Upgrade Now
        </span>
        <div className="spacer" />
      </div>
    </React.Fragment>
  );
}
