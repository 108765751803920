import style from "./invite-buttons-topbar.module.css";
import InviteToBoardButton from "./invite-to-board-button";
import InviteVisitorsButton from "./invite-visitors-button";

export default function InviteButtonsTopBar({ onInviteClick }: { onInviteClick?: (title?: string) => void }) {
  const shareButtonStyle = {
    minWidth: "72.78px",
    height: "30px",
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: "center",
    fontSize: "14.2292px",
    marginRight: "4px",
  };

  return (
    <div className={style.container} data-testid="shareButton">
      {false && <InviteVisitorsButton />}
      <InviteToBoardButton customStyle={shareButtonStyle} onInviteClick={onInviteClick} />
    </div>
  );
}
