import EditSeatsModal from "frontend/modals/edit-seats-modal";
import Modal from "frontend/modal/modal";
import tracking from "frontend/tracking";
import React, { useEffect, useState } from "react";
import consts from "shared/consts";
import style from "./payment-notification.module.css";
import { getPathPrefix } from '../utils/getPathPrefix';

export default function PaymentNotification({ unpaidSeats }: { unpaidSeats: number }) {
  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "payment-notification-shown", "unpaid seats", unpaidSeats);
  }, []);

  const [showEditSeatsModal, setShowEditSeatsModal] = useState(false);

  function renderPaymentNotification() {
    return (
      <div className={style.toolBarWarning}>
        <img src={getPathPrefix("/images/warning-i.svg")}/>
        <span className={style.description}>
          {`You have ${unpaidSeats} unpaid seats. Add them by clicking`}{" "}
          <a className={style.moreInfo} onClick={() => setShowEditSeatsModal(true)}>
            here
          </a>
          .
        </span>
        <div className="spacer" />
      </div>
    );
  }

  return (
    <React.Fragment>
      {showEditSeatsModal && (
        <Modal dimBackground={true}>
          <EditSeatsModal onDismiss={() => setShowEditSeatsModal(false)} />
        </Modal>
      )}
      {renderPaymentNotification()}
    </React.Fragment>
  );
}
