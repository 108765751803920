import style from "./toast.module.css";

export default function AskToEditToast({ onClose }: { onClose?: () => void }) {

  return (
    <div className={style.container}>
      <div className={style.content}>
        {"Your request has been sent to the account admin"}
      </div>
    </div>
  );
}
