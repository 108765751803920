import tracking from "frontend/tracking";
import { useEffect } from "react";
import consts from "shared/consts";
import ErrorModal from "./error-modal";

export default function MaxUsersPerBoardError({
  boardName,
  onDismiss,
  maxAllowedCollaborators,
}: {
  boardName: string;
  onDismiss: () => void;
  maxAllowedCollaborators: number;
}) {
  useEffect(() => {
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.USER_PRESENCE,
      "users_limit_reached",
      "limit",
      maxAllowedCollaborators
    );
  }, []);

  return (
    <ErrorModal title="Oops!" onDismiss={onDismiss}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          fontSize: "14px",
        }}
      >
        <span style={{ fontWeight: "500" }}>"{boardName}" canvas</span>
        <span>
          Reached it's limit of {maxAllowedCollaborators} online collaborators.
          <br />
          Upgrade your plan to increase the limit
        </span>
      </div>
    </ErrorModal>
  );
}
