import { logout } from "frontend/api";
import classNames from "classnames";
import {
  createMenuOption,
  createMenuSeparator,
  OptionsContextMenu,
  OptionsContextMenuTheme,
} from "frontend/context-menu/optionsContextMenu";
import Modal from "frontend/modal/modal";
import useStateValue from "frontend/state/value";
import { mondayURL } from "frontend/utils/url-utils";
import { useRouter } from "next/router";
import { useRef, useState } from "react";
import style from "./profile.module.css";
import { useCustomerPortal } from "frontend/billingUtils";
import { SyncService } from "frontend/services/syncService";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { useClientInfo } from "frontend/subscriptions";
import { getPathPrefix } from '../utils/getPathPrefix';

export default function Profile({
  isBoardHeader,
  syncService,
}: {
  isBoardHeader: boolean;
  syncService?: SyncService<RW>;
}) {
  const reps = syncService?.getReplicache();
  const clientID = reps?.clientID;
  const info = clientID && reps ? useClientInfo(clientID, reps) : undefined;

  const [{ user }] = useStateValue();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const profileRef = useRef(null);
  const router = useRouter();

  function profileClicked() {
    setShowProfileMenu(true);
  }

  function showMyProfile() {
    if (!user?.account) {
      return;
    }
    const url = mondayURL(user.account.slug, `p/users/${user.id}`);
    window.open(url, "__blank")?.focus();
    setShowProfileMenu(false);
  }

  function showSettings() {
    if (!user || !user.account) {
      return;
    }
    const url = mondayURL(user.account.slug, `admin`);
    window.open(url, "__blank")?.focus();
    setShowProfileMenu(false);
  }

  function handleLogout() {
    logout().then(() => {
      if (!user || !user.account) {
        return;
      }
      const url = mondayURL(user.account.slug, `p/whiteboard/logout`);
      router.push(url);
    });
  }

  const adminMenuOptions = [
    createMenuOption({
      title: "My Profile",
      iconPath: getPathPrefix("/images/my-profile-icon.svg"),
      id: "my-profile",
      onClick: showMyProfile,
    }),
    createMenuOption({
      title: "Settings",
      iconPath: getPathPrefix("/images/settings-icon.svg"),
      id: "settings",
      onClick: showSettings,
    }),
    ...(user?.planInfo?.source !== "touch"
      ? [
          createMenuOption({
            title: "Billing",
            iconPath: getPathPrefix("/images/billing-icon.svg"),
            id: "billing",
            onClick: () => useCustomerPortal({ type: "admin" }),
            disabled: !!user?.isGuest,
          }),
        ]
      : []),
    createMenuSeparator(),
  ];

  const menuOptions = [
    createMenuOption({ title: "Logout", iconPath: getPathPrefix("/images/logout-icon.svg"), id: "logout", onClick: handleLogout }),
  ];

  if (user?.isAdmin) {
    menuOptions.unshift(...adminMenuOptions);
  }

  return (
    <div>
      {user && (
        <div
          className={classNames(style.profile, { [style.border]: isBoardHeader })}
          ref={profileRef}
          onClick={profileClicked}
        >
          <img src={user.thumbnail} style={{ borderColor: info?.userInfo.color }} />
        </div>
      )}
      {showProfileMenu && (
        <Modal dimBackground={false}>
          <OptionsContextMenu
            options={menuOptions}
            attachToRef={profileRef}
            onDismiss={() => setShowProfileMenu(false)}
            theme={OptionsContextMenuTheme.light}
            customStyle={{ padding: "4px 16px 14px" }}
          />
        </Modal>
      )}
    </div>
  );
}
