import { User, Board, BoardPermission, Permission } from "shared/datamodel/schemas";
import {
  InstanceType,
  instanceActionsByRole,
  InstanceAction,
  getUserAndInstanceRole,
  UserRole,
} from "shared/datamodel/schemas/ability";
import { Project } from "shared/datamodel/schemas/project";
import { Team } from "shared/datamodel/schemas/team";
import useFeatureValue from "./use-features";
import consts from "shared/consts";

export default function useAbility() {
  const applyRestrictedLimitation = useFeatureValue(consts.FEATURE_NAMES.APPLY_VIEWER_RESTRICTED) === "true";
  function canCreateInstance(user: User, instance?: Team | Project | Board, type?: InstanceType) {
    const { userRole, instanceRole } = getUserAndInstanceRole({ user, instance, type , applyRestrictedLimitation});
    return instanceActionsByRole[userRole][instanceRole].has(InstanceAction.Create);
  }

  function canEditInstance(
    user: User | null,
    instance: Team | Project | Board,
    type: InstanceType,
  ) {
    if (type === InstanceType.Board && !(instance as Board).hasMemberAccess) {
      return instance.permission === BoardPermission.public
    }
    if (type === InstanceType.Board && instance.permission === BoardPermission.public) {
      return true;
    } 
    if (!user) return false;
    const { userRole, instanceRole } = getUserAndInstanceRole({ user, instance, type, applyRestrictedLimitation});
    return instanceActionsByRole[userRole][instanceRole].has(InstanceAction.Edit);
  }

  function canViewInstance(user: User, instance: Team | Project | Board, type: InstanceType) {
    const { userRole, instanceRole } = getUserAndInstanceRole({ user, instance, type, applyRestrictedLimitation});
    return instanceActionsByRole[userRole][instanceRole].has(InstanceAction.View);
  }

  function canInviteToInstance(user: User | null, instance: Team | Project | Board, type: InstanceType) {
    if (!user) return false;
    const { userRole, instanceRole } = getUserAndInstanceRole({ user, instance, type, applyRestrictedLimitation});
    return instanceActionsByRole[userRole][instanceRole].has(InstanceAction.Invite);
  }

  function canShareBoard(user: User, instance: Board) {
    const { userRole, instanceRole } = getUserAndInstanceRole({ user, instance, type: InstanceType.Board, applyRestrictedLimitation});
    return instanceActionsByRole[userRole][instanceRole].has(InstanceAction.Share);
  }

  function canPerformAnyAction(user: User | null, instance: Team | Project | Board, type: InstanceType) {
    if (type === InstanceType.Board && !(instance as Board).hasMemberAccess) {
      return instance.permission !== BoardPermission.private
    }
    if (type === InstanceType.Board && instance.permission === BoardPermission.public) {
      return true;
    } 
    if (!user) return false;
    const { userRole, instanceRole } = getUserAndInstanceRole({ user, instance, type, applyRestrictedLimitation});
    return instanceActionsByRole[userRole][instanceRole].size > 0;
  }

  function canMemberEditInstance(member: User, instance: Team | Project, type: InstanceType) {
    const { userRole } = getUserAndInstanceRole({ user: member, instance, type, applyRestrictedLimitation});
    if (userRole === UserRole.Restricted) {
      return false;
    }
    if (type === InstanceType.Team) {
      return (instance as Team).userTeamPermissions.find((user) => user.id === member.id)?.permission === Permission.editor;
    } else {
      return (instance as Project).userProjectPermissions.find((user) => user.id.toString() === member.id)?.permission === Permission.editor;
    }
  }

  return {
    canCreateInstance,
    canEditInstance,
    canViewInstance,
    canInviteToInstance,
    canShareBoard,
    canPerformAnyAction,
    canMemberEditInstance,
  };
}