import { FieldProps, FormFieldContainer } from "./form-field-container";
import { useCallback, useEffect, useRef, useState } from "react";
import style from "./form-bubbles-field.module.css";
import { CloseIcon } from "../svg-shapes";
import classNames from "classnames";
import { useOnClickOutside } from "usehooks-ts";

type BubblesFieldProps = FieldProps<BubbleFieldMutation> & {
    value: BubbleFieldValue[];
    highlightInvalid: boolean;
    placeholder?: string;
    customStyle?: any;
    autoFocus?: boolean;
};

export type BubbleFieldValue = {
    value: string;
    displayValue: string;
    isValid: boolean;
}

export type BubbleFieldMutation = {
    value: string;
    action: "add" | "delete";
}

export default function FormBubblesField(props: BubblesFieldProps) {
    const { value: values, placeholder, highlightInvalid, autoFocus = false } = props;

    const ref = useRef<any | null>(null);
    const inputRef = useRef<any | null>(null);
    const tagsRef = useRef<any | null>(null);
    const [ focusedTagIndex, setFocusedTagIndex ] = useState<number | null>(null);
    const [isInputFieldFocused, setIsInputFieldFocused] = useState(autoFocus);

    useOnClickOutside(ref, () => {
        const value = inputRef.current?.value;
        if (value) {
            props.onChange({ value, action: "add" });
            inputRef.current.value = "";
        }
    })

    const handleKeydown = useCallback((e: any) => {
        if (!inputRef.current) {
            return;
        }
        const value = inputRef.current?.value;
        if (["Tab", "Enter", ",", " " ].includes(e.key) && value.length > 0) {
            e.preventDefault();
            props.onChange({ value, action: "add" });
            inputRef.current.value = "";
        } else if (e.key === "ArrowLeft" && value.length === 0) {
            setFocusedTagIndex((index) => {
                return Math.max(0, (index ?? values.length) - 1);
            });
        } else if (e.key === "ArrowRight" && value.length === 0) {
            if (focusedTagIndex === values.length - 1) {
                setFocusedTagIndex(null);
            } else {
                setFocusedTagIndex((index) => {
                    if (index === null) {
                        return null;
                    }
                    const newIndex = index + 1;
                    if (newIndex === values.length) {
                        return 0;
                    }
                    return newIndex;
                });
            }
        } else if (e.key === "Backspace" && value.length === 0) {
            if (focusedTagIndex !== null) {
                const deleteValue = values[focusedTagIndex];
                onDelete(deleteValue.value);
                setFocusedTagIndex((idx) => {
                    if (values.length === 1) { // delete the last one
                        return null;
                    }
                    if (idx === values.length - 1) { // was the last one - select new last
                        return idx - 1;
                    }
                    return idx; // keep same place
                })
            } else if (values.length > 0) {
                setFocusedTagIndex(values.length - 1);
            }
        }
    }, [ values, focusedTagIndex ]);

    const handlePaste = useCallback((e: any) => {
        const text = e.clipboardData?.getData("text");
        if (!text) {
            return;
        }

        e.preventDefault();
        const values: string[] = text.split(/[\n\s,]+/)
        values.forEach(value => props.onChange({ value, action: "add" }));
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", handleKeydown);
        inputRef.current?.addEventListener("paste", handlePaste);
        return () => {
            window.removeEventListener("keydown", handleKeydown);
            inputRef.current?.removeEventListener("paste", handlePaste)
        }
    }, [ handleKeydown, handlePaste ]);

    useEffect(() => {
        if (focusedTagIndex === null) {
            inputRef.current.focus();
        } else if (values.length > 0) {
            tagsRef.current.focus();
        }
    }, [ focusedTagIndex, values ]);

    function clickedBackground() {
        inputRef.current.focus();
    }

    function onDelete(value: string) {
        props.onChange({ value, action: "delete" })
    }

    const renderTags = useCallback(() => {
        return values.map((value, idx) => {
            const isFocused = focusedTagIndex === idx;
            return <Tag value={ value } isFocused={ isFocused } highlightInvalid={ highlightInvalid }
                        onDelete={ onDelete } key={ value.value }/>
        })
    }, [ focusedTagIndex, values, highlightInvalid ]);

  return (
    <FormFieldContainer {...props} isFocused={isInputFieldFocused}>
      <div className={style.container} onClick={clickedBackground} ref={ref} style={props.customStyle}>
        {values.length > 0 && (
          <div className={style.valuesContainer} ref={tagsRef} tabIndex={2}>
            {renderTags()}
          </div>
        )}
        <input
          autoFocus={autoFocus}
          autoComplete={"nope"}
          ref={inputRef}
          placeholder={placeholder}
          className={style.input}
          onFocus={() => {
            setIsInputFieldFocused(true);
            setFocusedTagIndex(null);
          }}
          onBlur={() => {
            setIsInputFieldFocused(false);
          }}
        />
      </div>
    </FormFieldContainer>
  );
}

function Tag({
  value,
  isFocused,
  highlightInvalid,
  onDelete,
}: {
  value: BubbleFieldValue;
  isFocused: boolean;
  highlightInvalid: boolean;
  onDelete: (value: string) => void;
}) {
  return (
    <div
      className={classNames(style.tag, {
        [style.invalid]: !value.isValid,
        [style.focused]: isFocused,
        [style.highlight]: highlightInvalid,
      })}
      key={value.value}
      onClick={() => onDelete(value.value)}
    >
      <span>{value.displayValue}</span>
      <CloseIcon color={!value.isValid && highlightInvalid ? "#F05C30" : "#113357"} style={{marginTop: 6}}/>
    </div>
  );
}