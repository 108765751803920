import { userAtom, userTipsAtom } from "state-atoms/users-atoms";
import { useAtomValue } from "jotai";
import { TipName } from "shared/datamodel/schemas/tip";

const onboardingTips = [ TipName.onboardingTeamSize, TipName.onboardingInvite ];

export default function useShowOnboarding() {
    const userTips = useAtomValue(userTipsAtom);
    const user = useAtomValue(userAtom);
    return user?.serialNumber === 1 && !onboardingTips.every((tip) => userTips.has(tip));
}