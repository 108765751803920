import { CSSProperties } from "react";
import svgStyle from "./svg-shapes.module.css";

export type Direction = "up" | "down" | "left" | "right";
export type TriangleType = "nice" | "equilateral";

type TrianglePoints = {
  [Property in Direction]: string;
};
const NiceTriangleViewBox = "0 0 6 6";
const NiceTrianglePoints: TrianglePoints = {
  up: "0,6 3,2 6,6 0,6",
  down: "0,0 3,4 6,0 0,0",
  left: "6,6 2,3 6,0 6,6",
  right: "0,0 4,3 0,6 0,0",
};

const EquilateralTriangleViewVox = "0 0 1 1";
const EquilateralTrianglePoints: TrianglePoints = {
  up: "0,1 0.5,0.133 1,1 0,1",
  down: "0,0 0.5,0.866 1,0 0,0",
  left: "1,0 0.133,0.5 1,1 1,0",
  right: "0,0 0.866,0.5 0,1 0,0",
};

export function createTriangle(type: TriangleType, direction: Direction, fill?: string) {
  fill ||= "white";
  if (type === "nice") {
    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="${NiceTriangleViewBox}" preserveAspectRatio="xMidYMid meet">
                <polygon className="triangle" points="${NiceTrianglePoints[direction]}" fill="${fill}"/>
            </svg>`;
  } else if (type === "equilateral") {
    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="${NiceTriangleViewBox}" preserveAspectRatio="xMidYMid meet">
                <polygon className="triangle" points="${EquilateralTrianglePoints[direction]}" fill="${fill}"/>
            </svg>`;
  } else {
    console.error("Unknown triangle type");
    return "";
  }
}

export function Triangle({
  type,
  direction,
  fill,
  onclick,
  id,
}: {
  type: TriangleType;
  direction: Direction;
  fill?: string;
  onclick?: React.MouseEventHandler<SVGElement>;
  id?: string;
}): JSX.Element {
  if (type === "nice") {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" id={id} viewBox={NiceTriangleViewBox} preserveAspectRatio="xMidYMid meet">
        <polygon className="triangle" points={NiceTrianglePoints[direction]} fill={fill} onClick={onclick} />
      </svg>
    );
  } else if (type === "equilateral") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id={id}
        viewBox={EquilateralTriangleViewVox}
        preserveAspectRatio="xMidYMid meet"
      >
        <polygon className="triangle" points={EquilateralTrianglePoints[direction]} fill={fill} onClick={onclick} />
      </svg>
    );
  } else {
    console.error("Unknown triangle type");
    return <p />;
  }
}

export function Arrow({ direction, stroke, scale = 1 }: { direction: Direction; stroke: string; scale?: number }) {
  let angle = 0;
  switch (direction) {
    case "left":
      break; // the default arrow points left
    case "right":
      angle = 180;
      break;
    case "up":
      angle = 90;
      break;
    case "down":
      angle = 270;
      break;
  }

  const realScale = Math.max(0, Math.min(1, scale));
  const size = realScale * 21;
  return (
    <svg width={size} height={size} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform={`rotate(${angle},8,5)`}>
        <path
          d="M2.91281 4.34002L5.99771 1.25512L4.91421 0.171631L0 5.08584L4.91421 10.0001L5.99771 8.91657L2.95116 5.87002L16 5.87002L16 4.34002L2.91281 4.34002Z"
          fill={stroke}
        />
      </g>
    </svg>
  );
}

// no stroke - must style with css to see
export const pencilIcon = (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 14H4.39239L13.2974 5.09498C13.7473 4.64512 14 4.03498 14 3.39878C14 2.76259 13.7473 2.15245 13.2974 1.70259C12.8476 1.25273 12.2374 1 11.6012 1C10.965 1 10.3549 1.25273 9.90502 1.70259L1 10.6076V14Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.05688 2.55078L12.4493 5.94317" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export function CloseIcon({
  color = "#000",
  scale = 1,
  onClick = () => {},
  style,
}: {
  color?: string;
  scale?: number;
  onClick?: () => void;
  style?: CSSProperties;
}) {
  const realScale = Math.min(3, Math.max(0, scale));
  const size = realScale * 10;

  return (
    <div className={svgStyle.closeIcon}>
      <svg
        style={style}
        width={size}
        height={size}
        viewBox={`0 0 9 10`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
      >
        <path d="M1 0.712158L8.27864 8.52004" stroke={color} strokeLinecap="round" />
        <path d="M8.2793 0.711426L1.00066 8.5193" stroke={color} strokeLinecap="round" />
      </svg>
    </div>
  );
}

export function FrameIcon({ size, color = "#000" }: { size: number; color?: string }) {
  return (
    <svg width={size} height={(12 / 11) * size} viewBox="0 0 11 12" xmlns="http://www.w3.org/2000/svg">
      <line x1="2.33368" y1="1.36133" x2="2.33368" y2="11.3613" stroke={color} strokeLinecap="round" />
      <line x1="8.75" y1="1.36133" x2="8.75" y2="11.3613" stroke={color} strokeLinecap="round" />
      <line x1="0.5" y1="9.52817" x2="10.5" y2="9.52817" stroke={color} strokeLinecap="round" />
      <line x1="0.5" y1="3.11133" x2="10.5" y2="3.11133" stroke={color} strokeLinecap="round" />
    </svg>
  );
}

export function CommentsIcon({ size, color = "black" }: { size: number; color?: string }) {
  return (
    <svg width={size} height={size} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 11.8613V2.92383C1 2.37682 1.2173 1.85221 1.60409 1.46542C1.99089 1.07863 2.51549 0.861328 3.0625 0.861328H9.9375C10.4845 0.861328 11.0091 1.07863 11.3959 1.46542C11.7827 1.85221 12 2.37682 12 2.92383V7.04883C12 7.59584 11.7827 8.12044 11.3959 8.50724C11.0091 8.89403 10.4845 9.11133 9.9375 9.11133H3.75L1 11.8613Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3.75 3.61133H9.25" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.75 6.36133H7.875" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export function BackArrow({ onClick, style, color }: { onClick?: () => void; style?: CSSProperties; color?: string }) {
  return (
    <div style={{ color: color, ...style }} className={svgStyle.backArrow} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
        <path d="M1 5.5H12.2503" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1 5.5L5.50011 10.0001" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1 5.50011L5.50011 1" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      <span> Back </span>
    </div>
  );
}
