import style from "./end-of-trial-modal.module.css";
import Modal from "frontend/modal/modal";
import StyledButton from "frontend/ui-components/styled-button";
import { downgradeTouchAccountToBasic } from "frontend/api";
import { useAtom } from "jotai";
import { isBusyAtom } from "state-atoms/general-atoms";
import { getPathPrefix } from '../utils/getPathPrefix';

export default function EndOfTrialModal({ onDismiss, trialDays }: { onDismiss: any; trialDays: number }) {
  const [, setBusy] = useAtom(isBusyAtom);

  function renderCheckmark(color: string) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none">
        <path
          d="M1 5.12356L4.63879 8.65533L11.9164 1.5918"
          stroke={color}
          strokeWidth="1.3552"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  function renderInfo() {
    const workCanvasOffer = [
      "Blazing Fast Multiplayer",
      "Task management",
      "Commenting",
      "A world of templates",
      "Quality file export",
    ];

    return (
      <div className={style.infoContainer}>
        {workCanvasOffer.map((item, index) => {
          return (
            <div className={style.infoLine} key={index}>
              {renderCheckmark("#1973FF")}
              <span className={style.infoLineText}>{item}</span>
            </div>
          );
        })}
      </div>
    );
  }

  function renderSmallModal() {
    return (
      <div className={style.container}>
        <div className={style.rightSection}>
          <div className={style.rightSectionContent}>
            <img src={getPathPrefix("/images/checkout/end-of-trial.png")}/>
          </div>
        </div>
        <div className={style.leftSection}>
          <div className={style.checkoutWrapper}>
            <span className={style.checkoutHeader}>{`Your ${trialDays} day trial is over`} </span>
            <p>
              <span className={style.infoLineText} style={{ fontWeight: 600, lineHeight: "normal" }}>
                You’ll be downgraded to WorkCanvas Basic.
              </span>
              <span className={style.infoLineText} style={{ display: "inline", lineHeight: "normal" }}>
                You can upgrade back to Pro by contacting your account manager or by upgrading through the app.
              </span>
            </p>
            <div className={style.divider}></div>
            <span className={style.checkoutSubtitle}>
              <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.5 2L12.8333 8L17 4L15.3333 14H3.66667L2 4L6.16667 8L9.5 2Z"
                  fill="#F5B53D"
                  stroke="#F5B53D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
              Upgrade to access to all the Pro features
            </span>
            {renderInfo()}
            <div className={style.divider}></div>
            <div className={style.buttonsContainer}>
              <StyledButton
                title={"Continue"}
                onClick={() => {
                  setBusy(true);
                  onDismiss();
                  downgradeTouchAccountToBasic().then(() => {
                    window.location.reload();
                  }).catch((e) => {
                    console.error("error downgrading account to basic", e);
                  }).finally(() => {
                    setBusy(false);
                  });
                }}
                customStyle={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <Modal dimBackground={true}>{renderSmallModal()}</Modal>;
}
