import { getSignupURL } from "frontend/hooks/use-authentication";
import { BoardConfig, defaultBoardConfig } from "frontend/state/board-state";
import useStateValue from "frontend/state/value";
import tracking from "frontend/tracking";
import { openURL } from "frontend/utils/window-utils";
import React, { useEffect, useRef, useState } from "react";
import consts from "shared/consts";
import style from "./template-preview.module.css";
import { CanvasTemplateMinimal } from "../../shared/datamodel/schemas/canvas-template";
import MiniStage from "frontend/canvas-designer-new/mini-stage";
import Checkout from "frontend/billing/checkout";
import useFeatureValue from "frontend/hooks/use-features";
import { useIsNoTrialBillingABTestGroup } from "frontend/hooks/use-feature-flag/ab-tests";
import TemplatePreviewContent from "./template-preview-content";

export default function TemplatePreview({
  template,
  onChooseTemplate,
  onUpgradeModalShown,
  onUpgradeModalHidden,
  boardConfig = defaultBoardConfig,
  onDismiss,
}: {
  template: CanvasTemplateMinimal;
  onChooseTemplate: (template: CanvasTemplateMinimal) => void;
  onUpgradeModalShown?: () => void;
  onUpgradeModalHidden?: () => void;
  boardConfig?: BoardConfig;
  onDismiss?: () => void;
}) {
  const isIntegrationTemplate = !!template.metadata?.mondaySolutionId || template.alias == "org-chart-integration";

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [previewLoaded, setPreviewLoaded] = useState(isIntegrationTemplate);
  const isMondayIntegrationAllowed = useFeatureValue(consts.FEATURE_NAMES.MONDAY_INTEGRATION) === "true";
  const isNoTrialBillingABTestGroup = useIsNoTrialBillingABTestGroup();

  const shouldRenderPreview = useRef(true);

  const [{ user }] = useStateValue();

  //if template name does not ends with the word "template" add it:
  const upgradeRequired = template.upgradeRequired || (!isMondayIntegrationAllowed && isIntegrationTemplate);

  useEffect(() => {
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.TEMPLATES,
      "template-preview-shown",
      `${upgradeRequired ? "upgrade required" : "no upgrade required"}`,
      template.name
    );
  }, []);

  useEffect(() => {
    if (showUpgradeModal) {
      onUpgradeModalShown && onUpgradeModalShown();
    } else {
      onUpgradeModalHidden && onUpgradeModalHidden();
    }
  }, [showUpgradeModal]);

  function renderUpgradeIcon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
        <path
          d="M8.5 1L11.8333 7L16 3L14.3333 13H2.66667L1 3L5.16667 7L8.5 1Z"
          stroke="#7517F8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  function renderUpgradeFooter() {
    return (
      <div onClick={upgrade} className={style.upgradeFooter}>
        <div className={style.upgradeFooterContent}>
          {renderUpgradeIcon()}
          <span className={style.upgradeText}>
            Upgrade to unlock <span className={style.upgradeLink}>all of our pro templates</span>
          </span>
        </div>
      </div>
    );
  }

  function upgrade() {
    tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.UPGRADE_CTA);
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "upgrade-cta-click", `paid-template`, template.name);
    if (boardConfig.isOpenSession) {
      const url = getSignupURL();
      openURL(url);
    } else {
      setShowUpgradeModal(true);
    }
  }

  function renderTemplatePreview(previewType: "image" | "canvas") {
    switch (previewType) {
      case "image":
        return (
          <div className={style.previewImageContainer}>
            <div className={style.previewImage}>
              {template.previewImageURL && <img src={template.previewImageURL!} />}
            </div>
            {upgradeRequired && renderUpgradeFooter()}
          </div>
        );
      case "canvas":
        return (
          <div className={style.canvasPreview}>
            <MiniStage width={511} height={368} templateId={template.id} onLoad={setPreviewLoaded} />
            {false && upgradeRequired && renderUpgradeFooter()}
          </div>
        );
      default:
        break;
    }
  }

  function getUpgradeText() {
    const isDowngraded = user?.planInfo?.is_downgraded;

    const tryText = isNoTrialBillingABTestGroup ? "Try Now" : "Try it free";

    return isDowngraded ? "Upgrade to unlock" : tryText;
  }

  function renderPreview() {
    const upgradeText = getUpgradeText();

    return (
      <TemplatePreviewContent
        template={template}
        onChooseTemplate={onChooseTemplate}
        upgradeRequired={upgradeRequired}
        upgrade={upgrade}
        previewLoaded={previewLoaded}
        isIntegrationTemplate={isIntegrationTemplate}
        renderTemplatePreview={renderTemplatePreview}
        upgradeText={upgradeText}
      />
    );
  }

  function renderUpgradeModal() {
    const sourceName = isIntegrationTemplate ? "monday-integration" : "templates";
    return (
      <Checkout
        user={user!}
        source={{ name: sourceName, value: template.name }}
        onDismiss={() => {
          shouldRenderPreview.current = false;
          setShowUpgradeModal(false);
          onDismiss && onDismiss();
        }}
      />
    );
  }

  return showUpgradeModal ? (
    renderUpgradeModal()
  ) : shouldRenderPreview.current ? (
    renderPreview()
  ) : (
    <React.Fragment></React.Fragment>
  ); //We are doing these checks to fix a bug where when we closed the payment modal, the preview would load again and a result,
  // the mini stage would load and would execute a network call to fetch the templat's data
}
